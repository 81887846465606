import styled from 'styled-components';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

const StyledCustomCursor = styled.div`
	-webkit-font-smoothing: antialiased;
	align-items: center;
	background-color: ${({ isMenuVisible }) => (isMenuVisible ? 'white' : 'var(--logo-color)')};
	border-radius: 100%;
	content: '';
	display: block;
	height: 6px;
	justify-content: center;
	letter-spacing: 1.5px;
	opacity: ${({ isOverSlider, isMobileDevice, isBlendMode }) => ((isOverSlider || isBlendMode) && !isMobileDevice ? 0 : 1)};
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	text-transform: uppercase;
	transition: transform 0s ease-out, background-color 0.3s;
	width: 6px;
	will-change: transform;
	z-index: 150;
	&.cursor-black {
		background-color: ${({ theme }) => theme.colors.black} !important;
	}
	&.cursor-white {
		background-color: ${({ theme }) => theme.colors.white} !important;
	}
`;

const StyledCustomCursorAfter = styled.div`
	-webkit-font-smoothing: antialiased;
	backface-visibility: hidden;
	background: ${({ isBlendMode }) => (isBlendMode ? 'white' : 'transparent')};
	border: 1px solid ${({ theme, isBlendMode }) => (isBlendMode ? theme.colors.black : theme.colors.green)};
	border-radius: 100%;
	content: '';
	display: block;
	height: ${({ isBlendMode }) => (isBlendMode ? '44px !important' : '34px')};
	left: 0;
	mix-blend-mode: ${({ isBlendMode }) => (isBlendMode ? 'difference' : 'unset')};
	opacity: ${({ isOverSlider, isMobileDevice }) => (isOverSlider && !isMobileDevice ? 0 : 1)};
	perspective: 1000;
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	top: 0;
	transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
	width: ${({ isBlendMode }) => (isBlendMode ? '44px !important' : '34px')};
	z-index: 100;
`;

const StyledCustomCursorAfterRectangle = styled.div`
	-webkit-animation-fill-mode: forwards;
	-webkit-font-smoothing: antialiased;
	backface-visibility: hidden;
	background: transparent;
	border: 1px solid ${({ theme }) => theme.colors.green};
	content: '';
	display: block;
	height: 34px;
	left: 0;
	opacity: 0;
	perspective: 1000;
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	top: 0;
	transform-origin: center;
	transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1), width 0.7s cubic-bezier(0.16, 1, 0.3, 1),
		height 0.7s cubic-bezier(0.16, 1, 0.3, 1), opacity 0s linear;
	width: 34px;
	z-index: 100;
`;

const StyledCustomCursorAfterSlider = styled.div`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		//display: ${({ isOverSlider }) => (!isOverSlider ? 'flex' : 'none')};
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		box-shadow: ${({ theme }) => theme.colors.green} 0 0 15px;
		//left: calc(100vw - 190px);
		background-color: ${({ theme }) => theme.colors.green};
		color: ${({ theme }) => theme.colors.white};
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		text-align: center;
		justify-content: center;
		align-items: center;
		width: 140px;
		height: 140px;
		border-radius: 50%;
		padding: 0 20px;
		opacity: ${({ isOverSlider }) => (isOverSlider ? 1 : 0)};
		//cursor: none;
		pointer-events: none;
		// transform: translate(-50%, -50%);
		transition: width 0.25s ease-in-out, height 0.25s ease-in-out, display 0.3s ease-in-out, opacity 0.2s ease-in;

		span.down {
			display: none;
			font-size: 14px;
		}
		span.up {
			display: flex;
		}
		&.mouse-down {
			width: 90px;
			height: 90px;
			span.down {
				display: flex;
			}
			span.up {
				display: none;
			}
		}
	}
`;

class CustomCursor extends Component {
	_isMounted = false;

	state = {
		isLinkHovered: false,
		isSlider: false,
		isMobileDevice: false,
		isNews: false,
		isVerticalSlider: false,
		isVideoModule: false,
		isBlendMode: false,
		isGallery: false,
	};

	mouse = { x: 0, y: 0 };

	lastUpdateCall = null;

	activeLinkItem = '';

	linkItems = [];

	sliderModuleElement = '';

	newsModuleElement = '';

	verticalSliderElement = '';

	videoPlayButtonTextPl = '';

	videoPlayButtonTextEn = '';

	componentDidMount() {
		this.setState({ isMobileDevice: isMobile });
		this._isMounted = true;

		this.sliderModuleElement = document.getElementById('gallery-content');
		this.newsModuleElement = document.getElementById('news-content');
		this.verticalSliderElement = document.getElementById('vertical-slider');
		this.customMenuCursor = document.getElementById('custom-cursor');
		this.customMenuCursorAfter = document.getElementById('custom-cursor-after');
		this.customMenuCursorAfterRectangle = document.getElementById('custom-cursor-after-rectangle');
		document.addEventListener('mousemove', (e) => this.setCursorPosition(e));
		document.addEventListener('scroll', (e) => this.toggleLinkHoveredFalse(e));
		this.addAnimationsListeners();
	}

	componentDidUpdate(prevProps, prevState) {
		const { pageRemoteId, currentLang } = this.props;
		const { isLinkHovered } = this.state;
		if (prevProps.pageRemoteId !== pageRemoteId || prevProps.currentLang !== currentLang) {
			this.removeAnimationsListeners();
			this.addAnimationsListeners();
		}

		// if (prevProps.backgroundColorState !== backgroundColorState) {
		// this.customMenuCursor.style.backgroundColor = backgroundColorState === 'black' ? `white` : 'black';
		// }
		if (prevState.isLinkHovered !== isLinkHovered) {
			if (isLinkHovered) {
				const navItemBox = this.activeLinkItem.getBoundingClientRect();
				const stuckX = Math.ceil(navItemBox.left + navItemBox.width / 2);
				const stuckY = Math.ceil(navItemBox.top + navItemBox.height / 2);

				// const animationType = '';
				if (this.activeLinkItem.classList.contains('custom-slider')) {
					this.animationType = 'custom-slider';
				} else if (this.activeLinkItem.classList.contains('rectangle-button')) {
					this.animationType = 'rectangle';
				} else if (
					this.activeLinkItem.classList.contains('footer-social-media') ||
					this.activeLinkItem.classList.contains('change-lang')
				) {
					this.animationType = 'circle-progress';
				} else if (this.activeLinkItem.classList.contains('footer-email-address')) {
					this.animationType = 'footer-email';
				} else {
					this.animationType = 'circle';
				}

				if (this.animationType === 'circle') {
					this.circleAnimation(stuckX, stuckY);
				} else if (this.animationType === 'rectangle') {
					this.rectangleAnimation(stuckX, stuckY);
				} else if (this.animationType === 'circle-progress') {
					this.circleProgressAnimation();
				} else if (this.animationType === 'footer-email') {
					this.sliderAnimation();
				} else if (this.animationType === 'custom-slider') {
					this.sliderAnimation();
				}
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.lastUpdateCall = null;
		document.removeEventListener('mousemove', (e) => this.setCursorPosition(e));
		document.removeEventListener('scroll', (e) => this.toggleLinkHoveredFalse(e));
		this.removeAnimationsListeners();
	}

	addAnimationsListeners = () => {
		this.linkItems = document.querySelectorAll('.custom-cursor-animation');
		this.linkItems.forEach((item) => {
			item.addEventListener('mouseover', (e) => this.toggleLinkHoveredTrue(e));
			item.addEventListener('mouseout', (e) => this.toggleLinkHoveredFalse(e));
		});
	};

	removeAnimationsListeners = () => {
		this.linkItems.forEach((item) => {
			item.removeEventListener('mouseover', (e) => this.toggleLinkHoveredTrue(e));
			item.removeEventListener('mouseout', (e) => this.toggleLinkHoveredFalse(e));
		});
	};

	circleAnimation = (stuckX, stuckY) => {
		// line animation on hover +32px, border width x2 +4px, 32 - 4 = 28
		const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 28;
		// half of border +1px
		const animatedCursorPositionTranslate = Math.floor((this.activeLinkItem.clientWidth + 28) / 2);
		this.customMenuCursorAfter.style.width = `${animatedCursorCircleWidth}px`;
		this.customMenuCursorAfter.style.height = `${animatedCursorCircleWidth}px`;
		this.customMenuCursorAfter.style.transform = `translate3d(${stuckX - animatedCursorPositionTranslate}px, ${
			stuckY - animatedCursorPositionTranslate
		}px, 0px)`;
	};

	rectangleAnimation = (stuckX, stuckY) => {
		this.customMenuCursorAfter.style.display = 'none';
		const animatedCursorCircleWidth = this.activeLinkItem.clientWidth;
		const animatedCursorCircleHeight = this.activeLinkItem.clientHeight;
		// const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 20;
		// const animatedCursorCircleHeight = this.activeLinkItem.clientHeight + 20;
		this.customMenuCursorAfterRectangle.style.opacity = `1`;
		this.customMenuCursorAfterRectangle.style.width = `${animatedCursorCircleWidth}px`;
		this.customMenuCursorAfterRectangle.style.height = `${animatedCursorCircleHeight}px`;
		const animatedCursorPositionTranslateX = Math.floor(animatedCursorCircleWidth / 2 + 1);
		const animatedCursorPositionTranslateY = Math.floor(animatedCursorCircleHeight / 2);
		this.customMenuCursorAfterRectangle.style.transform = `translate3d(${stuckX - animatedCursorPositionTranslateX}px, ${
			stuckY - animatedCursorPositionTranslateY
		}px, 0px)`;
		// // line animation on hover +32px, border width x2 +4px, 32 - 4 = 28
		// // const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 28;
		// const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 20;
		// // const animatedCursorCircleHeight = this.activeLinkItem.clientHeight + 10;
		// const animatedCursorCircleHeight = this.activeLinkItem.clientHeight + 20;
		// // half of border +1px
		// const animatedCursorPositionTranslateX = Math.floor(animatedCursorCircleWidth / 2);
		// const animatedCursorPositionTranslateY = Math.floor(animatedCursorCircleHeight / 2);
		// this.customMenuCursorAfter.style.width = `${animatedCursorCircleWidth}px`;
		// this.customMenuCursorAfter.style.height = `${animatedCursorCircleHeight}px`;
		// this.customMenuCursorAfter.style.borderRadius = `0`;
		// this.customMenuCursorAfter.style.transform = `translate3d(${stuckX - animatedCursorPositionTranslateX}px, ${
		// 	stuckY - animatedCursorPositionTranslateY
		// }px, 0px)`;
	};

	sliderAnimation = () => {
		this.customMenuCursorAfter.style.display = 'none';
	};

	circleProgressAnimation = () => {
		this.customMenuCursorAfter.style.display = 'none';
	};

	getCursorPosition = () => {
		return this.customMenuCursor.getBoundingClientRect();
	};

	cleanAnimationFantastic = () => {
		const cursorPosition = this.customMenuCursor.getBoundingClientRect();
		this.customMenuCursorAfter.style.transform = `translate3d(${cursorPosition.x - 15}px, ${cursorPosition.y - 15}px, 0px)`;
		this.customMenuCursorAfter.style.display = `block`;
		this.customMenuCursorAfter.style.width = `34px`;
		this.customMenuCursorAfter.style.height = `34px`;
		this.customMenuCursor.style.visibility = 'visible';
		// eslint-disable-next-line no-template-curly-in-string
		this.customMenuCursor.style.backgroundColor = " ${({ isMenuVisible }) => (isMenuVisible ? 'white' : 'var(--logo-color)')}";
		this.customMenuCursorAfter.style.borderRadius = `100%`;
		this.customMenuCursorAfterRectangle.style.width = `34px`;
		this.customMenuCursorAfterRectangle.style.height = `34px`;
		this.customMenuCursorAfterRectangle.style.opacity = `0`;
	};

	setCursorPosition = (e) => {
		const { isLinkHovered } = this.state;
		// const { pageRemoteId } = this.props;
		const cursorSet = e.target.closest('[data-cursor]');
		const videoPlaying = e.target.closest('[data-video-playing]');

		const videoPlayingAttribute = videoPlaying && videoPlaying.getAttribute('data-video-playing');
		this.videoPlayButtonTextPl = videoPlayingAttribute === 'playing' ? 'zatrzymaj' : 'włącz';
		this.videoPlayButtonTextEn = videoPlayingAttribute === 'playing' ? 'play' : 'pause';

		if (cursorSet && cursorSet.getAttribute('data-cursor') === 'black' && !this.customMenuCursor.classList.contains('cursor-black')) {
			this.customMenuCursor?.classList?.add('cursor-black');
			this.customMenuCursor?.classList?.remove('cursor-white');
		} else if (
			cursorSet &&
			cursorSet.getAttribute('data-cursor') === 'white' &&
			!this.customMenuCursor.classList.contains('cursor-white')
		) {
			this.customMenuCursor?.classList?.add('cursor-white');
			this.customMenuCursor?.classList?.remove('cursor-black');
		}
		if (!cursorSet && this.customMenuCursor.classList.contains('cursor-white')) {
			this.customMenuCursor?.classList?.remove('cursor-white');
		} else if (!cursorSet && this.customMenuCursor.classList.contains('cursor-black')) {
			this.customMenuCursor.classList?.remove('cursor-black');
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'green-circle') {
			this.setState({ isNews: true });
			this.setState({ isSlider: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'green-circle-slider') {
			this.setState({ isSlider: true });
			this.setState({ isNews: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'green-circle-gallery') {
			this.setState({ isSlider: false });
			this.setState({ isNews: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: true });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'green-circle-vertical-slider') {
			this.setState({ isSlider: false });
			this.setState({ isNews: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: true });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'green-circle-video-module') {
			this.setState({ isSlider: false });
			this.setState({ isNews: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: true });
			this.setState({ isGallery: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'blend-mode-circle') {
			this.setState({ isSlider: false });
			this.setState({ isNews: false });
			this.setState({ isBlendMode: true });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: false });
		} else {
			this.setState({ isNews: false });
			this.setState({ isSlider: false });
			this.setState({ isBlendMode: false });
			this.setState({ isVerticalSlider: false });
			this.setState({ isVideoModule: false });
			this.setState({ isGallery: false });
		}
		// if (pageRemoteId === 48) {
		// this.checkIfMouseOverSlider(e);
		// this.checkIfMouseOverNews(e);
		// }

		this.customMenuCursor.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0px)`;
		if (this.lastUpdateCall) {
			cancelAnimationFrame(this.lastUpdateCall);
		}
		if (!isLinkHovered) {
			this.lastUpdateCall = requestAnimationFrame(() => this.setCursorAfterPosition(e));
		}
	};

	// checkIfMouseOverSlider = (e) => {
	// 	const tempValue = this.sliderModuleElement?.offsetTop + this.sliderModuleElement?.offsetHeight;
	// 	const { isSlider } = this.state;
	// 	if (!isSlider && e.pageY >= this.sliderModuleElement?.offsetTop && e.pageY <= tempValue) {
	// 		this.setState({ isSlider: true });
	// 	} else if ((isSlider && e.pageY < this.sliderModuleElement?.offsetTop) || e.pageY > tempValue) {
	// 		this.setState({ isSlider: false });
	// 	}
	// };

	// checkIfMouseOverNews = (e) => {
	// 	const tempValue = this.newsModuleElement?.offsetTop + this.newsModuleElement?.offsetHeight;
	// 	const { isNews } = this.state;
	// 	if (!isNews && e.pageY >= this.newsModuleElement?.offsetTop && e.pageY <= tempValue) {
	// 		this.setState({ isNews: true });
	// 	} else if ((isNews && e.pageY < this.newsModuleElement?.offsetTop) || e.pageY > tempValue) {
	// 		this.setState({ isNews: false });
	// 	}
	// };

	setCursorAfterPosition = (e) => {
		this.customMenuCursorAfter.style.transform = `translate3d(${e.clientX - 15}px, ${e.clientY - 15}px, 0px)`;
		this.customMenuCursorAfterRectangle.style.transform = `translate3d(${e.clientX - 15}px, ${e.clientY - 15}px, 0px)`;
		this.lastUpdateCall = null;
	};

	toggleLinkHoveredTrue = (e) => {
		const { isLinkHovered } = this.state;
		if (!isLinkHovered) {
			e.stopPropagation();
			this.activeLinkItem = e.currentTarget;
			this.activeLinkItem?.classList?.add('hover');
			this.setState({ isLinkHovered: true });
		}
	};

	toggleLinkHoveredFalse = (e) => {
		const { isLinkHovered } = this.state;
		if (isLinkHovered) {
			this.activeLinkItem?.classList?.remove('hover');
			e.stopPropagation();
			this.cleanAnimationFantastic();
			this.setState({ isLinkHovered: false });
		}
	};

	render() {
		const { isSlider, isNews, isVerticalSlider, isVideoModule, isMobileDevice, isBlendMode, isGallery } = this.state;
		const { currentLang, isMenuVisible } = this.props;

		return (
			<>
				<StyledCustomCursor
					isBlendMode={isBlendMode}
					isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}
					isMobileDevice={isMobileDevice}
					isMenuVisible={isMenuVisible}
					id="custom-cursor"
				/>
				<StyledCustomCursorAfter
					isBlendMode={isBlendMode}
					isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}
					isMobileDevice={isMobileDevice}
					id="custom-cursor-after"
				/>
				<StyledCustomCursorAfterRectangle id="custom-cursor-after-rectangle" />

				<StyledCustomCursorAfterSlider isOverSlider={isSlider} id="custom-cursor-slider">
					<span className="down">{currentLang === 'pl' ? 'przesuń' : 'move'}</span>
					<span className="up">{currentLang === 'pl' ? 'przesuń lub kliknij' : 'move or click'}</span>
				</StyledCustomCursorAfterSlider>

				<StyledCustomCursorAfterSlider isOverSlider={isGallery} id="custom-cursor-gallery">
					<span className="down">{currentLang === 'pl' ? 'otwórz lub przesuń' : 'open or move'}</span>
					<span className="up">{currentLang === 'pl' ? 'otwórz lub przesuń' : 'open or move'}</span>
				</StyledCustomCursorAfterSlider>

				<StyledCustomCursorAfterSlider isOverSlider={isNews} id="custom-cursor-news">
					<span className="down">{currentLang === 'pl' ? 'otwórz' : 'click'}</span>
					<span className="up">{currentLang === 'pl' ? 'otwórz' : 'click'}</span>
				</StyledCustomCursorAfterSlider>

				<StyledCustomCursorAfterSlider isOverSlider={isVerticalSlider} id="custom-cursor-vertical-slider">
					<span className="down">{currentLang === 'pl' ? 'otwórz' : 'click'}</span>
					<span className="up">{currentLang === 'pl' ? 'otwórz' : 'click'}</span>
				</StyledCustomCursorAfterSlider>

				<StyledCustomCursorAfterSlider isOverSlider={isVideoModule} id="custom-cursor-video">
					<span className="down">{currentLang === 'pl' ? this.videoPlayButtonTextPl : this.videoPlayButtonTextEn}</span>
					<span className="up">{currentLang === 'pl' ? this.videoPlayButtonTextPl : this.videoPlayButtonTextEn}</span>
				</StyledCustomCursorAfterSlider>
			</>
		);
	}
}

export default connect((state) => ({
	isMenuVisible: state.app.isMenuVisible,
}))(CustomCursor);
