const disableBgAnimationOnPageChange = () => {
	const bgEl = document.getElementById('layout-bg');
	if (bgEl) {
		bgEl.style.transition = 'none';

		setTimeout(() => {
			bgEl.style.transition = 'background-color 0.4s ease-in-out';
		}, 600);
	}
};

export default disableBgAnimationOnPageChange;
