import React, { Component } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import ButtonRectangle from '../common/ButtonRectangle';

const StyledCookieInfoWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	opacity: 0;
	position: fixed;
	right: 0;
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
	width: 100%;
	z-index: 10;
	@media (min-width: 768px) {
		flex-direction: row;
	}
	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: flex-start;
	}
	@media (min-width: 1440px) {
		width: calc(50vw + 40px + 90px);
		flex-direction: row;
		justify-content: flex-start;
	}
	&.visible {
		opacity: 1;
		transform: translateX(0);
	}
`;

const StyledImageWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px 20px;
	width: 100%;
	@media (min-width: 768px) {
		width: 160px;
	}
	@media (min-width: 1024px) {
		width: 220px;
		padding: 10px 40px;
	}
	@media (min-width: 1440px) {
		align-items: flex-start;
		padding: 20px 55px 20px 40px;
		width: unset;
	}
	img {
		width: 100px;
		@media (min-width: 1024px) {
			width: 180px;
		}
	}
`;

const StyledInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px 20px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 20px 60px 20px 0;
	}
	@media (min-width: 1024px) {
		padding: 25px 60px 20px 0;
	}
	@media (min-width: 1440px) {
		padding: 40px 80px 40px 0;
		width: calc(100% - 300px);
	}
`;

const StyledInfoText = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 11px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 19px;
	margin-bottom: 10px;
	@media (min-width: 1440px) {
		font-size: 12px;
		line-height: 20px;
	}
`;

class CookieInfo extends Component {
	state = {
		showCookieInfo: false,
	};

	componentDidMount() {
		this.setState({ showCookieInfo: Cookies.get('cookie-kangur') === undefined });
	}

	closeCookieInfo() {
		this.setState({ showCookieInfo: false });
		Cookies.set('cookie-kangur', true, { expires: 100 });
	}

	render() {
		const { cookieInfoData } = this.props;
		const { showCookieInfo } = this.state;
		const cookieIcon = cookieInfoData?.cookiesIkona?.[0]?.url || '';
		const cookieText = cookieInfoData?.cookiesTekst || '';
		const cookieButtonText = cookieInfoData?.cookiesNapisNaPrzycisku || '';

		return (
			<StyledCookieInfoWrapper data-cursor="black" className={showCookieInfo ? 'visible' : ''}>
				<StyledImageWrapper>
					<img alt="img" src={cookieIcon} />
				</StyledImageWrapper>
				<StyledInfoWrapper>
					<StyledInfoText dangerouslySetInnerHTML={getHtmlFromRedactorField(cookieText)} />
					<div onClick={this.closeCookieInfo.bind(this)}>
						<ButtonRectangle className="black-text uppercase">{cookieButtonText}</ButtonRectangle>
					</div>
				</StyledInfoWrapper>
			</StyledCookieInfoWrapper>
		);
	}
}

export default CookieInfo;
