const themeSettings = {
	colors: {
		black: 'rgba(0, 0, 0, 1)',
		blackMenuWrapper: 'rgba(2, 2, 2, 0.65)',
		blackMenu: `rgba(35,35,35,1)`,
		white: 'rgba(255, 255, 255, 1)',
		greyLight: `rgba(222, 222, 223, 1)`,
		greyDark: `rgba(112, 112, 112, 1)`,
		greyDarkLine: `rgba(112, 112, 112, 0.3)`,
		green: 'rgba(4, 233, 157, 1)',
		greenAlpha: 'rgba(4, 233, 157, 0.30)',
		greenButton: 'rgba(4, 233, 157, 0.80)',
	},

	fontFamily: {
		mainFont: 'Saira, serif',
	},

	fontWeight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	},

	device: {
		w_320: `(min-width: 320px)`,
		w_425: `(min-width: 425px)`,
		w_768: `(min-width: 768px)`,
		w_1024: `(min-width: 1024px)`,
		w_1280: `(min-width: 1280px)`,
		w_1440: `(min-width: 1440px)`,
		w_1920: `(min-width: 1920px)`,
		w_2560: `(min-width: 2560px)`,
	},
};

export default themeSettings;
