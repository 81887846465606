import React, { Component } from 'react';
import lottie from 'lottie-web';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { connect } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { isMobile } from 'react-device-detect';
import getSlug from '../../utils/getSlug';
import setInitialColors from '../../utils/setInitialColors';

const StyledLogoAnimationWrapper = styled.div`
	.lottie-progress {
		& path {
			fill: var(--logo-color);
			stroke: var(--logo-color);
		}
	}
	&.footer {
		.lottie-progress {
			& path {
				fill: ${({ theme }) => theme.colors.white} !important;
				stroke: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}

	&.desktop {
		&:hover {
			.lottie-progress {
				.ai {
					path {
						fill: ${({ theme }) => theme.colors.green};
						stroke: ${({ theme }) => theme.colors.green};
					}

					&:first-of-type {
						path {
							fill: var(--logo-color);
							stroke: var(--logo-color);
						}
					}
				}
			}

			&.footer {
				.lottie-progress {
					.ai {
						path {
							fill: ${({ theme }) => theme.colors.green} !important;
							stroke: ${({ theme }) => theme.colors.green} !important;
						}

						&:first-of-type {
							path {
								fill: ${({ theme }) => theme.colors.white} !important;
								stroke: ${({ theme }) => theme.colors.white} !important;
							}
						}
					}
				}
			}
		}
	}
`;

class LogoAnimation extends Component {
	state = {
		isFooter: false,
		isMobileDevice: false,
	};

	loopCounter = 0;

	activeFrame = 0;

	scrollFromTop = 0;

	logoAnimationEl = '';

	componentDidMount() {
		const { animationJson, pageRemoteId, remoteTypeName, remoteParent } = this.props;

		this.setState({ isMobileDevice: isMobile });
		this.lottieProgress = lottie.loadAnimation({
			container: document.querySelector('.lottie-progress'),
			renderer: 'svg',
			loop: false,
			autoplay: false,
			path: animationJson,
		});

		this.logoAnimationEl = document.getElementById('logo-animation-wrapper');
		setInitialColors(pageRemoteId, remoteTypeName, remoteParent);
		this.changeLogoColorIfScrollToFooter();
		window.addEventListener('scroll', this.animateLogoOnScroll);
	}

	componentDidUpdate(prevProps) {
		const { pageRemoteId, lang, remoteTypeName, remoteParent } = this.props;
		if (prevProps.pageRemoteId !== pageRemoteId || prevProps.lang !== lang) {
			this.loopCounter = 0;
			this.activeFrame = 0;
			this.scrollFromTop = 0;
			setInitialColors(pageRemoteId, remoteTypeName, remoteParent);
			window.scrollTo({ top: 0, behavior: 'auto' });
			this.changeLogoColorIfScrollToFooter();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.animateLogoOnScroll);
	}

	animateLogoOnScroll = () => {
		// change totalHeight to change speed of animation
		const totalHeight = 1000;
		this.changeLogoColorIfScrollToFooter();
		const totalFrameIndex = this.lottieProgress.totalFrames - 1;
		if (this.scrollFromTop <= window.pageYOffset && this.activeFrame >= totalFrameIndex) {
			this.loopCounter += 1;
		} else if (this.loopCounter > 0 && this.scrollFromTop > window.pageYOffset && this.activeFrame <= 0) {
			this.loopCounter -= 1;
		}

		this.scrollFromTop = window.pageYOffset;
		const scrollPercentage = (this.scrollFromTop * 100) / totalHeight;

		const tempActiveFrame =
			scrollPercentage >= 0 ? Math.floor((scrollPercentage * totalFrameIndex) / 100) - this.loopCounter * totalFrameIndex : 0;

		if (tempActiveFrame > totalFrameIndex) {
			this.activeFrame = totalFrameIndex;
		} else if (tempActiveFrame < 0) {
			this.activeFrame = 0;
		} else this.activeFrame = tempActiveFrame;

		this.lottieProgress.goToAndStop(this.activeFrame, true);
		// let scrollPercentRounded = Math.round(scrollPercentage); // Just in case
	};

	changeLogoColorIfScrollToFooter = () => {
		const { isFooter } = this.state;
		const footerEl = document.getElementById('footer-el');
		// if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
		if (window.scrollY >= footerEl.offsetTop) {
			this.setState({ isFooter: true });
		} else if (isFooter) {
			this.setState({ isFooter: false });
		}
	};

	logoInitState = (activeSite, pathToNavigate) => {
		smoothscroll.polyfill();

		if (pathToNavigate !== '') {
			const path = `/${getSlug(pathToNavigate, activeSite)}`;
			navigate(path);
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		this.loopCounter = 0;
		this.activeFrame = 0;
		this.scrollFromTop = 0;
	};

	render() {
		const { activeSite, pageRemoteId } = this.props;
		const { isFooter, isMobileDevice } = this.state;

		let pathToNavigate = '';

		if (pageRemoteId !== '48') {
			pathToNavigate = 'strona-glowna';
		}

		const isFooterClass = isFooter ? 'footer' : '';
		const isDesktopClass = isMobileDevice ? '' : 'desktop';
		return (
			<StyledLogoAnimationWrapper
				id="logo-animation-wrapper"
				// data-cursor={!isFooter ? 'invert' : ''}
				className={`${isFooterClass} ${isDesktopClass}`}
				onClick={() => this.logoInitState(activeSite, pathToNavigate)}
			>
				<div className="lottie-progress" />
				<div className="container">
					<div className="content" />
				</div>
			</StyledLogoAnimationWrapper>
		);
	}
}

export default connect((state) => ({
	lang: state.app.lang,
}))(LogoAnimation);
