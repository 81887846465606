import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 30px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 45px;
	&.workflow {
		color: var(--text-color);
		transition: color 0.4s ease-in-out;

		@media ${({ theme }) => theme.device.w_768} {
			font-size: 34px;
			line-height: 45px;
		}
		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 46px;
			line-height: 63px;
		}

		@media ${({ theme }) => theme.device.w_1440} {
			font-size: 50px;
			line-height: 67px;
		}
	}

	&.menu {
		color: ${({ theme }) => theme.colors.greyLight};
		font-weight: ${({ theme }) => theme.fontWeight.thin};
		line-height: 50px;
		font-size: 26px;
		@media ${({ theme }) => theme.device.w_425} {
			line-height: 65px;
			font-size: 28px;
		}
		@media ${({ theme }) => theme.device.w_768} {
			font-size: 30px;
			line-height: 70px;
		}

		@media (min-width: 960px) {
			@media (orientation: landscape) {
				font-size: 24px;
				line-height: 50px;
			}
		}
		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 45px;
			line-height: 84px;
			margin-bottom: 20px;
			@media (orientation: landscape) {
				font-size: 36px;
				line-height: 48px;
			}
		}
		@media ${({ theme }) => theme.device.w_1440} {
			font-size: 50px;
			line-height: 84px;
			margin-bottom: 15px;

			&.last-child {
				margin-bottom: 0;
			}
		}
		&:hover {
			color: ${({ theme }) => theme.colors.green};
		}
	}
	&.table {
		color: var(--text-color);
	}
	&.mobile-space {
		transition: padding-bottom 0.2s ease-in-out;
		padding-bottom: 220%;
		@media (min-width: 550px) {
			padding-bottom: 200%;
		}
		@media ${({ theme }) => theme.device.w_768} {
			padding-bottom: 0%;
		}
	}
	&.product-categories {
		color: ${({ theme }) => theme.colors.greyLight};
		font-size: 12px;
		line-height: 35px;

		@media (min-width: 550px) {
			font-size: 14px;
			line-height: 45px;
		}

		@media ${({ theme }) => theme.device.w_768} {
			font-size: 16px;
			width: 70%;
			line-height: 25px;
			margin-bottom: 20px;
		}

		@media ${({ theme }) => theme.device.w_1024} {
			width: 100%;
			font-size: 17px;
			line-height: 30px;
			margin-bottom: 25px;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			width: 100%;
			font-size: 20px;
			line-height: 50px;
		}

		&:hover {
			color: ${({ theme }) => theme.colors.green};
		}
	}

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 50px;
		line-height: 67px;
	}
	&.black {
		color: ${({ theme }) => theme.colors.black};
	}
`;

const TextHeader = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextHeader;
