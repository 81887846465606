// eslint-disable-next-line no-unused-vars
const setInitialColors = (pageRemoteId, remoteTypeName = -1, remoteParent = 0) => {
	// eslint-disable-next-line radix
	if (parseInt(pageRemoteId) !== 48 && remoteTypeName !== 'kategoriaProduktow_kategoriaProduktow_Entry') {
		document.documentElement.style.setProperty('--background-color', 'white');
		document.documentElement.style.setProperty('--text-color', 'rgba(2, 2, 2, 1)');
		document.documentElement.style.setProperty('--logo-color', 'black');
		document.documentElement.style.setProperty('--filter', '0');
	} else if (
		// eslint-disable-next-line radix
		parseInt(pageRemoteId) !== 48 &&
		remoteTypeName === 'kategoriaProduktow_kategoriaProduktow_Entry' &&
		remoteParent.length !== 0
	) {
		document.documentElement.style.setProperty('--background-color', 'white');
		document.documentElement.style.setProperty('--text-color', 'rgba(2, 2, 2, 1)');
		document.documentElement.style.setProperty('--logo-color', 'black');
		document.documentElement.style.setProperty('--filter', '0');
	} else {
		document.documentElement.style.setProperty('--background-color', 'black');
		document.documentElement.style.setProperty('--text-color', 'rgba(222, 222, 223, 1)');
		document.documentElement.style.setProperty('--logo-color', 'white');
		document.documentElement.style.setProperty('--filter', '1');
	}
};

export default setInitialColors;
