import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import MenuContent from './MenuContent';
import { toggleIsMenuVisible, toggleIsChildrenVisible } from '../../state/app';

const MenuWrapper = styled.div`
	pointer-events: none;
	align-items: center;
	// background-color: ${({ theme }) => theme.colors.blackMenuWrapper};
	display: none;
	height: 100vh;
	justify-content: center;
	position: fixed;
	right: 0;
	top: 0;
	transition: background-color 0.2s;
	width: 62px;
	z-index: 11;
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: column;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		width: 80px;
	}
	&.visible {
		//background-color: transparent;
		transition: background-color 0.2s;
	}
	&.hidden {
		// background-color: ${({ theme }) => theme.colors.blackMenuWrapper};
		transition: background-color 0.2s;
	}
`;

const MenuImgWithTextWrapper = styled.div`
	pointer-events: all;
	@media (min-width: 1366px) {
		margin-top: 35px;
	}
	//&:hover {
	//	.menu-text {
	//		opacity: 0;
	//		transform: translateY(-50px);
	//	}
	//}
`;
const MenuImgWrapper = styled.div`
	//margin-left: -20px;
	align-items: center;

	border-radius: 100px;

	display: flex;
	height: 28px;
	justify-content: center;
	position: relative;
	width: 28px;
	z-index: 15;
	@media (min-width: 1024px) {
		&:hover {
			~ .menu-text {
				opacity: 0;
				transform: translateY(-50px);
			}
			div {
				background-color: var(--text-color);
				&:before,
				&:after {
					background-color: var(--text-color);
				}
			}
			&:before {
				opacity: 0;
			}
		}
	}
	&:before {
		width: 54px;
		height: 54px;
		background-color: ${({ theme }) => theme.colors.black};
		content: '';
		position: absolute;
		border-radius: 100px;
		transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1);
	}
	&.active {
		&:before {
			border: 1px solid ${({ theme }) => theme.colors.white};
			border-radius: 100px;
			content: '';
			height: 56px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 56px;
		}
	}
`;

const MenuImg = styled.div`
	//background-color: var(--text-color);
	//position: relative;
	background-color: ${({ theme }) => theme.colors.white};
	height: 1px;
	left: 0;
	top: 0;
	transform: rotate(-50deg);
	transition: all 0.25s;
	width: 26px;

	&:before,
	&:after {
		// background-color: var(--text-color);
		background-color: ${({ theme }) => theme.colors.white};
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		transition: all 0.25s;
		width: 26px;
	}
	&:before {
		top: -8px;
	}
	&:after {
		top: 8px;
	}
	&.active {
		background-color: transparent !important;
		transform: rotate(180deg);

		&:before,
		&:after {
			background-color: white !important;
			top: 0;
			transition: background-color 1.2s;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
`;

const MenuText = styled.p`
	color: var(--text-color);
	display: none;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 12px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	letter-spacing: 0px;
	margin-top: 17px;
	pointer-events: none;
	position: relative;
	right: 2px;
	text-transform: uppercase;
	transition: all 0.25s;
	@media (min-width: 1280px) {
		display: block;
	}

	&.active {
		opacity: 0;
	}
`;

const MenuMobileWrapper = styled.div`
	display: block;
	position: fixed;
	right: 30px;
	top: 30px;
	z-index: 99999999;
	@media (min-width: 1024px) {
		display: none;
	}
`;

const Menu = ({
	mainMenuData,
	submenuData,
	socialMediaData,
	isMenuVisible,
	activeSite,
	firstOtherLangSite,
	allPagesOtherLang,
	pageRemoteId,
	isIntroRendered,
	remoteTypeName,
}) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (isMenuVisible === true) {
			document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
		} else if (pageRemoteId === '48' && isIntroRendered === false) {
			document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
		} else {
			document.getElementsByTagName('html')[0].style.overflowY = 'auto';
		}
	});

	const changeMenuVisibility = () => {
		dispatch(toggleIsMenuVisible(!isMenuVisible));
		dispatch(toggleIsChildrenVisible(false));
	};
	return (
		<>
			<MenuWrapper className={isMenuVisible ? 'visible' : 'hidden'}>
				<MenuImgWithTextWrapper>
					<MenuImgWrapper className={`custom-cursor-animation ${isMenuVisible ? 'active' : ''}`} onClick={changeMenuVisibility}>
						<MenuImg className={isMenuVisible ? 'active' : ''} />
					</MenuImgWrapper>
					<MenuText className={`menu-text ${isMenuVisible ? 'active' : ''}`} onClick={changeMenuVisibility}>
						Menu
					</MenuText>
				</MenuImgWithTextWrapper>
			</MenuWrapper>
			<MenuMobileWrapper>
				<MenuImgWrapper className="custom-cursor-animation" onClick={changeMenuVisibility}>
					<MenuImg className={isMenuVisible ? 'active' : ''} />
				</MenuImgWrapper>
			</MenuMobileWrapper>
			<MenuContent
				mainMenuData={mainMenuData}
				submenuData={submenuData}
				socialMediaData={socialMediaData}
				activeSite={activeSite}
				firstOtherLangSite={firstOtherLangSite}
				allPagesOtherLang={allPagesOtherLang}
				pageRemoteId={pageRemoteId}
				changeMenuVisibility={changeMenuVisibility}
				remoteTypeName={remoteTypeName}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		isMenuVisible: state.app.isMenuVisible,
		isIntroRendered: state.app.isIntroRendered,
		isChildrenVisible: state.isChildrenVisible,
	}),
	null,
)(Menu);
