import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import TextBanner from '../typography/TextBanner';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import getSlug from '../../utils/getSlug';
import ChangeLanguageCustom from '../menu/ChangeLanguageCustom';

const StyledFooterWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	display: none;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
	padding: 0 80px;
	width: 100%;
	@media (min-width: 1440px) {
		display: flex;
	}
`;

const StyledFooterWrapperMobile = styled.div`
	//margin-bottom: 135px;
	//margin-top: 70px;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 70px 20px 135px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 70px 80px 0;
	}
	@media (min-width: 1024px) {
		display: none;
	}
`;

const StyledFooterWrapperTablet = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	display: none;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0 62px;
	@media (min-width: 1024px) {
		display: flex;
		padding-top: calc(40vh - 200px);
	}
	@media (min-width: 1440px) {
		display: none;
	}
`;

const StyledColumnsWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row;
	height: calc(100% - 115px);
	justify-content: space-between;
	padding-bottom: 120px;
	width: 100%;
`;

const StyledSingleColumnWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	width: 50%;
	&.right {
		padding-right: 0;
		@media (min-width: 1920px) {
			padding-right: calc(1 / 12 * 100%);
		}
	}
	&.left {
		max-width: 260px;
		@media (min-width: 1024px) {
			max-width: 370px;
		}
	}
	&.mobile {
		justify-content: flex-start;
		width: 100%;
	}
`;

const StyledBottomBarWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 115px;
	padding-bottom: 5vh;
	//padding-right: calc(1 / 12 * 100%);
	position: relative;
	width: 100%;
	&:before {
		background-color: #707070;
		content: '';
		height: 0.5px;
		left: 0;
		opacity: 0.3;
		position: absolute;
		top: 0;
		width: 100%;
	}
	&.tablet {
		//height: 165px;
		padding-right: 0;
	}
	//@media (min-width: 1024px) {
	//	padding-right: 0;
	//}
	@media (min-width: 1920px) {
		padding-right: calc(1 / 12 * 100%);
	}
`;

const StyledNavigationWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: calc(100% - 110px);
	&.mobile {
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
	}
	&.tablet {
		align-items: flex-start;
		flex-direction: column;
	}
`;

const StyledMobileNavigationColumnWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 200px;
	@media (min-width: 1024px) {
		flex-direction: row;
		width: auto;
	}
`;

const StyledPleoLogoWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	&.mobile {
		margin-top: 20px;
		margin-bottom: 40px;
	}
	&.tablet {
		justify-content: flex-start;
		padding-top: 12px;
	}
	@media (min-width: 1024px) {
		width: 110px;
	}
`;

const StyledPleoLogo = styled.img`
	width: 110px;
`;

const StyledImageWrapper = styled.img`
	margin-bottom: 60px;
	transform: rotate(60deg);
	width: 230px;
	&.tablet {
		margin-bottom: 0;
	}
`;

const StyledEmailWrapper = styled.div`
	position: relative;
	&:before {
		background-color: ${({ theme }) => theme.colors.green};
		bottom: 0;
		content: '';
		height: 2px;
		transform-origin: 23%;
		left: 0;
		position: absolute;
		transform: scaleX(0);
		transition: transform 0.3s ease-in-out;
		width: 100%;
	}
	&.mobile {
		margin-top: 50px;
	}
	&.hover {
		&:before {
			transform: scaleX(1);
			width: 100%;
		}
		div {
			div {
				color: ${({ theme }) => theme.colors.green};
				transition: color 0.2s ease-in;
				span.green {
					color: white;
					transition: color 0.35s ease-in;
				}
			}
		}
	}
`;

const StyledAddressText = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 40px;
	&.company-name {
		font-size: 30px;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
	}
`;

const StyledAddressAndSocialMediaWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: auto;
	width: 100%;
	@media (min-height: 900px) {
		margin-top: 130px;
	}
	&.mobile {
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: 65px;
		margin-top: 30px;
	}

	&.tablet {
		margin: 50px 0 60px;
	}
`;

const StyledSocialMediaAndLanguageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 60px;
	justify-content: flex-end;
	&.mobile {
		justify-content: space-between;
		margin-top: 50px;
	}
`;

const StyledSingleSocialMediaWrapper = styled.a`
	margin-right: 15px;
	position: relative;
	width: 60px;
	svg {
		height: 60px;
		left: 0;
		position: absolute;
		top: 0;
		width: 60px;
	}
	&.mobile {
		margin-left: 0;
		margin-right: 10px;
		width: auto;
		&:last-of-type {
			margin-right: 0;
		}
	}
	&.hover {
		div {
			background-image: url(${(props) => props.iconHover});
			transform: scale(1.1, 1.1);
		}
		svg {
			.progress__circle {
				stroke-width: 1px;
				stroke: transparent;
				transition: stroke 0.2s ease-in-out;
			}
			.progress__path {
				stroke-dashoffset: 0;
			}
		}
	}
	.progress__circle {
		fill: none;
		stroke: #fff;
		stroke-width: 1px;
		transition: stroke 0.7s ease-in-out;
	}
	.progress__path {
		fill: none;
		stroke: #04e99d;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		stroke-width: 1px;
		transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
	}
`;

const StyledSocialMediaIcon = styled.div`
	background-image: url(${(props) => props.icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 60px 60px;
	height: 60px;
	left: 0;
	position: absolute;
	top: 0;
	transition: transform 0.2s ease-in-out;
	width: 60px;
	&.mobile {
		position: relative;
	}
`;

const StyledSingleNavigationItem = styled(Link)`
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	line-height: 50px;
	text-transform: uppercase;
	@media (min-width: 1024px) {
		margin-right: 45px;
	}
	@media (min-width: 1440px) {
		margin-right: 30px;
	}
	&:hover {
		color: white;
	}
`;

const StyledCircleAndTextTabletWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 200px;
	width: 100%;
`;

const Footer = ({ activeSite, data, socialMediaData, firstOtherLangSite, allPagesOtherLang, pageRemoteId }) => {
	let leftColumnText;
	const bottomNavigationData = [];
	let rightColumnIcon;
	let rightColumnEmail;
	let rightColumnCompanyName;
	let rightColumnAddress;
	let pleoLogoUrl;
	let pleoLink;

	data.forEach((item) => {
		if (item.__typename === 'Craft_stopka_lewaKolumna_BlockType') {
			leftColumnText = item?.tekst || '';
		} else if (item.__typename === 'Craft_stopka_prawaKolumna_BlockType') {
			rightColumnIcon = item?.ikona?.[0]?.url || '';
			rightColumnEmail = item?.email || '';
			rightColumnCompanyName = item?.nazwaFirmy || '';
			rightColumnAddress = item?.adres || '';
		} else if (item.__typename === 'Craft_stopka_dolnaNawigacja_BlockType') {
			bottomNavigationData.push(item);
		} else if (item.__typename === 'Craft_stopka_logoPleo_BlockType') {
			pleoLink = item?.linkDoStrony || '';
			pleoLogoUrl = item?.logo?.[0]?.url || '';
		}
	});
	const bottomNavigationItems = bottomNavigationData?.[0]?.wybierzPodstrony || [];
	const socialMediaToDisplayInFooter = socialMediaData ? socialMediaData.filter((item) => item.widoczneWStopce === true) : [];
	const mobileBottomBarNavigationItemsFirstColumn = bottomNavigationItems.slice(0, 6);
	const mobileBottomBarNavigationItemsSecondColumn = bottomNavigationItems.slice(6, bottomNavigationItems.length);
	return (
		<div id="footer-el" data-cursor="white">
			<StyledFooterWrapperMobile>
				<StyledSingleColumnWrapper className="mobile left">
					<TextBanner>{leftColumnText}</TextBanner>
				</StyledSingleColumnWrapper>
				<StyledSocialMediaAndLanguageWrapper className="mobile">
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{socialMediaToDisplayInFooter.map((item) => {
							const link = item?.linkUrl || '';
							const logoUrl = item?.logo?.[0]?.url || '';
							const logoGreen = item?.logoZielone?.[0]?.url || '';
							const name = item?.nazwa || '';
							return (
								<StyledSingleSocialMediaWrapper
									className="footer-social-media custom-cursor-animation mobile"
									key={name}
									href={link}
									target="_blank"
									iconHover={logoGreen}
								>
									<StyledSocialMediaIcon className="mobile" icon={logoUrl} iconHover={logoGreen} />
									<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
										<path
											className="progress__circle"
											d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
										/>
										<path
											className="progress__path"
											d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
											pathLength="1"
										/>
									</svg>
								</StyledSingleSocialMediaWrapper>
							);
						})}
					</div>
					<ChangeLanguageCustom
						activeSite={activeSite}
						firstOtherLangSite={firstOtherLangSite}
						allPagesOtherLang={allPagesOtherLang}
						pageRemoteId={pageRemoteId}
						isOnFooter
					/>
				</StyledSocialMediaAndLanguageWrapper>
				<StyledEmailWrapper className="footer-email-address custom-cursor-animation mobile">
					<TextBanner className="footer-email">{rightColumnEmail}</TextBanner>
				</StyledEmailWrapper>
				<StyledAddressAndSocialMediaWrapper className="mobile">
					<StyledAddressText className="company-name">{rightColumnCompanyName}</StyledAddressText>
					<StyledAddressText dangerouslySetInnerHTML={getHtmlFromRedactorField(rightColumnAddress)} />
				</StyledAddressAndSocialMediaWrapper>
				<StyledNavigationWrapper className="mobile">
					<StyledMobileNavigationColumnWrapper>
						{mobileBottomBarNavigationItemsFirstColumn.map((item) => {
							const name = item?.title || '';
							const slug = item?.slug || '';
							return (
								<StyledSingleNavigationItem key={name} to={`/${getSlug(slug, activeSite)}`}>
									{name}
								</StyledSingleNavigationItem>
							);
						})}
					</StyledMobileNavigationColumnWrapper>
					<StyledMobileNavigationColumnWrapper>
						{mobileBottomBarNavigationItemsSecondColumn.map((item) => {
							const name = item?.title || '';
							const slug = item?.slug || '';
							return (
								<StyledSingleNavigationItem key={name} to={`/${getSlug(slug, activeSite)}`}>
									{name}
								</StyledSingleNavigationItem>
							);
						})}
					</StyledMobileNavigationColumnWrapper>
				</StyledNavigationWrapper>
				<StyledPleoLogoWrapper className="mobile" as="a" href={pleoLink} target="_blank">
					<StyledPleoLogo src={pleoLogoUrl} />
				</StyledPleoLogoWrapper>
			</StyledFooterWrapperMobile>
			<StyledFooterWrapperTablet>
				<StyledCircleAndTextTabletWrapper>
					<StyledImageWrapper className="tablet" src={rightColumnIcon} />
					<StyledAddressText dangerouslySetInnerHTML={getHtmlFromRedactorField(leftColumnText)} />
				</StyledCircleAndTextTabletWrapper>
				<StyledEmailWrapper className="footer-email-address custom-cursor-animation">
					<TextBanner className="footer-email">{rightColumnEmail}</TextBanner>
				</StyledEmailWrapper>
				<StyledAddressAndSocialMediaWrapper className="tablet">
					<div>
						<StyledAddressText className="company-name">{rightColumnCompanyName}</StyledAddressText>
						<StyledAddressText dangerouslySetInnerHTML={getHtmlFromRedactorField(rightColumnAddress)} />
					</div>
					<StyledSocialMediaAndLanguageWrapper>
						{socialMediaToDisplayInFooter.map((item) => {
							const link = item?.linkUrl || '';
							const logoUrl = item?.logo?.[0]?.url || '';
							const logoGreen = item?.logoZielone?.[0]?.url || '';
							const name = item?.nazwa || '';
							return (
								<StyledSingleSocialMediaWrapper
									className="footer-social-media custom-cursor-animation"
									key={name}
									href={link}
									target="_blank"
									iconHover={logoGreen}
								>
									<StyledSocialMediaIcon icon={logoUrl} iconHover={logoGreen} />
									<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
										<path
											className="progress__circle"
											d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
										/>
										<path
											className="progress__path"
											d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
											pathLength="1"
										/>
									</svg>
								</StyledSingleSocialMediaWrapper>
							);
						})}
						<ChangeLanguageCustom
							activeSite={activeSite}
							firstOtherLangSite={firstOtherLangSite}
							allPagesOtherLang={allPagesOtherLang}
							pageRemoteId={pageRemoteId}
							isOnFooter
						/>
					</StyledSocialMediaAndLanguageWrapper>
				</StyledAddressAndSocialMediaWrapper>
				<StyledBottomBarWrapper className="tablet">
					<StyledNavigationWrapper className="tablet">
						<StyledMobileNavigationColumnWrapper>
							{mobileBottomBarNavigationItemsFirstColumn.map((item) => {
								const name = item?.title || '';
								const slug = item?.slug || '';
								return (
									<StyledSingleNavigationItem key={name} to={`/${getSlug(slug, activeSite)}`}>
										{name}
									</StyledSingleNavigationItem>
								);
							})}
						</StyledMobileNavigationColumnWrapper>
						<StyledMobileNavigationColumnWrapper>
							{mobileBottomBarNavigationItemsSecondColumn.map((item) => {
								const name = item?.title || '';
								const slug = item?.slug || '';
								return (
									<StyledSingleNavigationItem key={name} to={`/${getSlug(slug, activeSite)}`}>
										{name}
									</StyledSingleNavigationItem>
								);
							})}
						</StyledMobileNavigationColumnWrapper>
					</StyledNavigationWrapper>
					<StyledPleoLogoWrapper className="tablet" as="a" href={pleoLink} target="_blank">
						<StyledPleoLogo src={pleoLogoUrl} />
					</StyledPleoLogoWrapper>
				</StyledBottomBarWrapper>
			</StyledFooterWrapperTablet>
			<StyledFooterWrapper>
				<StyledColumnsWrapper>
					<StyledSingleColumnWrapper className="left">
						<TextBanner>{leftColumnText}</TextBanner>
					</StyledSingleColumnWrapper>
					<StyledSingleColumnWrapper className="right">
						<StyledImageWrapper src={rightColumnIcon} />
						<StyledEmailWrapper className="footer-email-address custom-cursor-animation">
							<TextBanner className="footer-email">{rightColumnEmail}</TextBanner>
						</StyledEmailWrapper>
						<StyledAddressAndSocialMediaWrapper>
							<div>
								<StyledAddressText className="company-name">{rightColumnCompanyName}</StyledAddressText>
								<StyledAddressText dangerouslySetInnerHTML={getHtmlFromRedactorField(rightColumnAddress)} />
							</div>
							<StyledSocialMediaAndLanguageWrapper>
								{socialMediaToDisplayInFooter.map((item) => {
									const link = item?.linkUrl || '';
									const logoUrl = item?.logo?.[0]?.url || '';
									const logoGreen = item?.logoZielone?.[0]?.url || '';
									const name = item?.nazwa || '';
									return (
										<StyledSingleSocialMediaWrapper
											className="footer-social-media custom-cursor-animation"
											key={name}
											href={link}
											target="_blank"
											iconHover={logoGreen}
										>
											<StyledSocialMediaIcon icon={logoUrl} iconHover={logoGreen} />
											<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
												<path
													className="progress__circle"
													d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
												/>
												<path
													className="progress__path"
													d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
												32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
													pathLength="1"
												/>
											</svg>
										</StyledSingleSocialMediaWrapper>
									);
								})}
								<ChangeLanguageCustom
									activeSite={activeSite}
									firstOtherLangSite={firstOtherLangSite}
									allPagesOtherLang={allPagesOtherLang}
									pageRemoteId={pageRemoteId}
									isOnFooter
								/>
							</StyledSocialMediaAndLanguageWrapper>
						</StyledAddressAndSocialMediaWrapper>
					</StyledSingleColumnWrapper>
				</StyledColumnsWrapper>
				<StyledBottomBarWrapper>
					<StyledNavigationWrapper>
						<div data-cursor="blend-mode-circle">
							{bottomNavigationItems.map((item) => {
								const name = item?.title || '';
								const slug = item?.slug || '';
								return (
									<StyledSingleNavigationItem key={name} to={`/${getSlug(slug, activeSite)}`}>
										{name}
									</StyledSingleNavigationItem>
								);
							})}
						</div>
					</StyledNavigationWrapper>
					<StyledPleoLogoWrapper as="a" href={pleoLink} target="_blank">
						<StyledPleoLogo src={pleoLogoUrl} />
					</StyledPleoLogoWrapper>
				</StyledBottomBarWrapper>
			</StyledFooterWrapper>
		</div>
	);
};

export default Footer;
