const initialState = {
	isMenuVisible: false,
	isIntroRendered: false,
	isChildrenVisible: false,
	menuCircleAnglePosition: '0',
	menuActivePageKey: 0,
	lang: 'pl',
};

const MENU_CIRCLE_ANGLE_POSITION = 'MENU_CIRCLE_ANGLE_POSITION';
const MENU_ACTIVE_PAGE_KEY = 'MENU_ACTIVE_PAGE_KEY';
const IS_MENU_VISIBLE = 'IS_MENU_VISIBLE';
const IS_INTRO_RENDERED = 'IS_INTRO_RENDERED';
const CHANGE_LANG = 'CHANGE_LANG';
const IS_CHILDREN_VISIBLE = 'IS_CHILDREN_VISIBLE';

export const changeLang = (lang) => ({
	type: CHANGE_LANG,
	lang,
});

export const changeMenuCircleAnglePosition = (menuCircleAnglePosition) => ({
	type: MENU_CIRCLE_ANGLE_POSITION,
	menuCircleAnglePosition,
});

export const changeMenuActivePageKey = (menuActivePageKey) => ({
	type: MENU_ACTIVE_PAGE_KEY,
	menuActivePageKey,
});

export const toggleIsMenuVisible = (isMenuVisible) => ({
	type: IS_MENU_VISIBLE,
	isMenuVisible,
});

export const toggleIsChildrenVisible = (isChildrenVisible) => ({
	type: IS_CHILDREN_VISIBLE,
	isChildrenVisible,
});

export const toggleIsIntroRendered = (isIntroRendered) => ({
	type: IS_INTRO_RENDERED,
	isIntroRendered,
});

export default (state = initialState, action = {}) => {
	if (action.type === CHANGE_LANG) {
		return {
			...state,
			lang: action.lang,
		};
	}

	if (action.type === MENU_CIRCLE_ANGLE_POSITION) {
		return {
			...state,
			menuCircleAnglePosition: action.menuCircleAnglePosition,
		};
	}

	if (action.type === MENU_ACTIVE_PAGE_KEY) {
		return {
			...state,
			menuActivePageKey: action.menuActivePageKey,
		};
	}

	if (action.type === IS_MENU_VISIBLE) {
		return {
			...state,
			isMenuVisible: action.isMenuVisible,
		};
	}

	if (action.type === IS_CHILDREN_VISIBLE) {
		return {
			...state,
			isChildrenVisible: action.isChildrenVisible,
		};
	}

	if (action.type === IS_INTRO_RENDERED) {
		return {
			...state,
			isIntroRendered: action.isIntroRendered,
		};
	}

	return state;
};
