/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { connect, useDispatch } from 'react-redux';
import TextHeader2 from '../typography/TextHeader2';
import TextHeader from '../typography/TextHeader';
import {
	changeMenuActivePageKey,
	changeMenuCircleAnglePosition,
	toggleIsChildrenVisible,
	toggleIsMenuVisible,
} from '../../state/app'
import getSlug from '../../utils/getSlug';
import WhiteCircleWithDot from '../../images/common/white.svg';
import Logo from '../../assets/logo/logo.svg';
import ChangeLanguageCustom from './ChangeLanguageCustom';

const menuMobileAnimationOpen = (scaleBiggerValue, scaleSmallerValue) => keyframes`
	0% {
		opacity: 1;
		transform: translate(calc(50vw - 45px), calc(-0.5 * var(--inner-height) + 45px)) scale(${scaleBiggerValue});
		background-color: rgba(4, 233, 157, 1);
	}
	40% {
		transform: translate(0, 0) scale(${scaleBiggerValue});
		transition: ease-out;
	}
	40% {
		transform: scale(${scaleBiggerValue})
		translateX(0);
	}
	60% {
		opacity: 1;
		transform: scale(${scaleSmallerValue})
		translateX(0);
		background-color: black;
	}
	61% {
		transform: scale(${scaleBiggerValue});
	}
	99.95% {
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	100% {
		transform: scale(2);
		height: var(--inner-height);
		padding-bottom: 0;
		//border-radius: 0;
	}
`;

const menuMobileAnimationClose = (scaleBiggerValue, scaleSmallerValue) => keyframes`
	0% {
		opacity: 1;
		transform: scale(2) translateX(0);
		height: var(--inner-height);
		padding-bottom: 0;
		border-radius: 0;
		transition: ease-out;
	}
	0.1% {
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	60% {
		transform: scale(${scaleBiggerValue});
		background-color: black;
	}
	61% {
		opacity: 1;
		transform: scale(${scaleSmallerValue})
		translateX(0);
	}
	62%{
		opacity: 1;
		transform: scale(${scaleBiggerValue})
		translateX(0);
	}
	90% {
		background-color: rgba(4, 233, 157, 1);
		transform: translate(calc(50vw - 45px), calc(-0.5 * var(--inner-height) + 45px)) scale(${scaleBiggerValue});
	}
	91% {
		background-color: rgba(4, 233, 157, 1);
		transform: translate(calc(50vw - 45px), calc(-0.5 * var(--inner-height)  + 45px)) scale(${scaleBiggerValue});
	}
	100% {
		background-color: rgba(4, 233, 157, 1);
		transform: translate(calc(50vw - 45px), calc(-0.5 * var(--inner-height) + 45px)) scale(${scaleBiggerValue});
		opacity: 0;
	}
`;

const menuAnimationOpen = (scaleBiggerValue, scaleSmallerValue) => keyframes`
	0% {
		opacity: 1;
		transform: translateX(calc(50vw - 45px)) scale(${scaleBiggerValue});
		background-color: rgba(4, 233, 157, 1);
	}
	40% {
		transform: scale(${scaleBiggerValue})
		translateX(0);
		transition: ease-out;
	}
	40% {
		transform: scale(${scaleBiggerValue})
		translateX(0);
	}
	60% {
		opacity: 1;
		transform: scale(${scaleSmallerValue})
		translateX(0);
		background-color: black;
	}
	61% {
		transform: scale(${scaleBiggerValue});
	}
	99.9% {
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	100% {
		transform: scale(2);
		height: 100%;
		padding-bottom: 0;
		border-radius: 0;
	}
`;

const menuAnimationClose = (scaleBiggerValue, scaleSmallerValue) => keyframes`
	0% {
		opacity: 1;
		transform: scale(2) translateX(0);
		height: 100%;
		padding-bottom: 0;
		border-radius: 0;
		transition: ease-out;
	}
	0.1% {
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	60% {
		transform: scale(${scaleBiggerValue});
		background-color: black;
	}
	61% {
		opacity: 1;
		transform: scale(${scaleSmallerValue})
		translateX(0);
	}
	62%{
		opacity: 1;
		transform: scale(${scaleBiggerValue})
		translateX(0);
	}
	90% {
		background-color: rgba(4, 233, 157, 1);
		transform: translateX(calc(50vw - 45px)) scale(${scaleBiggerValue});
	}
	91% {
		background-color: rgba(4, 233, 157, 1);
		transform: translateX(calc(50vw - 45px)) scale(${scaleBiggerValue});
	}
	100% {
		background-color: rgba(4, 233, 157, 1);
		transform: translateX(calc(50vw - 45px)) scale(${scaleBiggerValue});
		opacity: 0;
	}
`;

const StyledMenuBgWrapper = styled.div`
	backface-visibility: hidden;
	height: 100%;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	transform: translate3d(0, 0, 0);
	width: 100%;
	z-index: 9;
	&:before {
		background: ${({ theme }) => theme.colors.black};
		border-radius: 100%;
		bottom: 0;
		content: '';
		height: 0;
		left: 0;
		margin: auto;
		opacity: 0;
		overflow: hidden;
		padding-bottom: 100%;
		position: absolute;
		right: 0;
		top: 0;
		transform: scale(0.03) translateX(1550%);
		width: 100%;
	}
	&.visible {
		opacity: 1;
		pointer-events: all;
		&:before {
			animation: ${menuMobileAnimationOpen(0.15, 0.1)} 0.5s linear forwards;
			opacity: 1;
		}
	}
	&.hidden {
		opacity: 1;
		pointer-events: none;
		&:before {
			animation: ${menuMobileAnimationClose(0.15, 0.1)} 0.5s linear forwards;
		}
	}
	@media (min-width: 768px) {
		&.visible {
			&:before {
				animation: ${menuMobileAnimationOpen(0.08, 0.03)} 0.8s ease-in forwards;
			}
		}
		&.hidden {
			&:before {
				animation: ${menuMobileAnimationClose(0.08, 0.03)} 0.8s ease-out forwards;
			}
		}
	}
	@media (min-width: 1024px) {
		&.visible {
			&:before {
				animation: ${menuAnimationOpen(0.05, 0.02)} 0.8s ease-in forwards;
			}
		}
		&.hidden {
			&:before {
				animation: ${menuAnimationClose(0.05, 0.02)} 0.8s ease-out forwards;
			}
		}
	}
	@media (min-width: 1336px) {
		&.visible {
			&:before {
				animation: ${menuAnimationOpen(0.04, 0.01)} 0.8s ease-in forwards;
			}
		}
		&.hidden {
			&:before {
				animation: ${menuAnimationClose(0.04, 0.01)} 0.8s ease-out forwards;
			}
		}
	}
	@media (min-width: 1440px) {
		&.visible {
			&:before {
				animation: ${menuAnimationOpen(0.03, 0.01)} 0.8s ease-in forwards;
			}
		}
		&.hidden {
			&:before {
				animation: ${menuAnimationClose(0.03, 0.01)} 0.8s ease-out forwards;
			}
		}
	}
	@media (min-width: 2560px) {
		&.visible {
			&:before {
				animation: ${menuAnimationOpen(0.02, 0.005)} 0.8s ease-in forwards;
			}
		}
		&.hidden {
			&:before {
				animation: ${menuAnimationClose(0.02, 0.005)} 0.8s ease-out forwards;
			}
		}
	}
`;
const StyledModuleWrapper = styled.div`
	align-items: stretch;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	left: 0;
	opacity: 0;
	padding: 40px;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: all 0.6s;
	width: 100%;
	z-index: 9;

	@media (min-width: 600px) {
		//height: 100vh;
		min-height: 100vh;
		min-height: -webkit-fill-available;
		padding: 40px;
	}
	@media (min-width: 768px) {
		padding: 30px 22px 30px 70px;
	}
	@media (min-width: 800px) {
		padding: 40px 11px 40px 60px;
	}
	@media (min-width: 810px) {
		padding: 70px 11px 70px 80px;
	}

	@media (min-width: 960px) {
		@media (orientation: landscape) {
			padding: 50px 2px 50px 60px;
		}
	}

	@media (min-width: 1020px) {
		padding: 70px 2px 70px 80px;
		@media (orientation: landscape) {
			padding: 40px 2px 40px 80px;
		}
	}

	@media (min-width: 1280px) {
		padding: 60px 4px 60px 80px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding: 45px 13px 45px 10%;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		padding: 45px 13px 45px 15%;
	}
	&.visible {
		opacity: 1;
		pointer-events: all;
		top: 0;
		transition: all 0.6s;
	}
	&.hidden {
		display: flex;
		opacity: 0;
		transition: all 0.6s;
	}
`;

const StyledLanguageWrapper = styled.div`
	align-items: flex-start;
	justify-content: space-between;
	@media (min-width: 1200px) {
		justify-content: flex-end;
	}
	&.up {
		display: none;
		@media ${({ theme }) => theme.device.w_1024} {
			display: flex;
		}
	}
	&.down {
		display: flex;
		margin-left: 10px;
		@media (min-width: 768px) {
			margin-left: 15px;
		}
		@media ${({ theme }) => theme.device.w_1024} {
			display: none;
		}
	}
`;

const StyledMainContentWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	opacity: 0;
	position: relative;
	transition: opacity 0.6s;
	transition-delay: 0.2s;
	&.visible {
		opacity: 1;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.2s;
	}
	@media (min-width: 400px) {
		height: 75%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		height: 55%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		height: 55%;
	}
`;

const MenuItemWrapper = styled.div`
	&:last-of-type {
		.product-categories {
			margin-bottom: 0;
		}
	}
	&.hidden {
		transition: opacity 0.2s;
		opacity: 0;
		transform: translate3d(0, 36px, 0);
	}
	&.visible {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		transition-delay: calc(${({ animationDelay }) => animationDelay} * 0.1s + 0.7s);
	}
	&.child-el {
		&.visible {
			transition-delay: calc(${({ animationDelay }) => animationDelay} * 0.1s + 0.05s);
		}
	}
`;

const MenuItemText = styled.div`
	position: relative;
	&.has-submenu {
		div::after {
			width: 15%;
			height: 2px;
			content: '';
			position: absolute;
			top: 16px;
			left: -19%;
			display: none;
			background-color: ${({ theme }) => theme.colors.greyDarkLine};
			@media ${({ theme }) => theme.device.w_768} {
				display: block;
				width: 90px;
				left: unset;
				right: -130px;
				top: 50%;
				bottom: unset;
				transform: translateY(-50%);
			}
			@media ${({ theme }) => theme.device.w_1024} {
				right: -120px;
			}
			@media (min-width: 1200px) {
				right: -140px;
			}
			@media (min-width: 1366px) {
				right: -160px;
			}
			@media ${({ theme }) => theme.device.w_1920} {
				width: 210px;
				left: unset;
				right: -340px;
			}
		}
	}
`;
const StyledChildrenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	transform: translate(10%, 0%);
	@media ${({ theme }) => theme.device.w_768} {
		display: flex;
	}

	@media (min-width: 550px) {
		transform: translate(10%, 0%);
	}

	@media ${({ theme }) => theme.device.w_768} {
		transform: translate(66%, 0);
	}
	@media (min-width: 960px) {
		@media (orientation: landscape) {
			transform: translate(70%, 0);
		}
	}
	@media ${({ theme }) => theme.device.w_1024} {
		//transform: translate(70%, -3px);
		transform: translate(70%, 0);
		@media (orientation: landscape) {
			transform: translate(70%, 0);
		}
	}
	@media (min-width: 1200px) {
		transform: translate(80%, 0);
	}

	@media ${({ theme }) => theme.device.w_1440} {
		transform: translate(80%, 0);
	}

	@media ${({ theme }) => theme.device.w_1920} {
		transform: translate(120%, 0);
	}
	&.visible {
		opacity: 1;
		pointer-events: all;
	}
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
`;

const StyledChildrenWrapperEmbedded = styled.div`
	transition: opacity 0.6s;
	transition-delay: 0.1s;
	&.visible {
		opacity: 1;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.2s;
	}
`;

const StyledExtendedContentWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	opacity: 0;
	transition: opacity 0.6s;
	transition-delay: 0.1s;
	@media ${({ theme }) => theme.device.w_768} {
		flex-direction: row;
		height: 108px;
		align-items: center;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		height: auto;
	}

	&.visible {
		opacity: 1;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.2s;
	}
`;

const StyledExtendedContentText = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
		&.submenu {
			flex-direction: column;
			align-items: flex-start;
			margin: 20px 0 30px;
			@media (min-width: 600px) {
				flex-direction: row;
			}
			@media ${({ theme }) => theme.device.w_768} {
				margin: 0px;
			}
		}

		.submenu-item {
			margin-right: 30px;
			font-size: 14px;
			line-height: 28px;
			color: ${({ theme }) => theme.colors.greyLight};
			@media ${({ theme }) => theme.device.w_768} {
				font-size: 18px;
				line-height: 34px;
			}
			@media ${({ theme }) => theme.device.w_1024} {
				margin-right: 50px;
			}
		}
	}
	.social-media-item {
		&:nth-child(-n + 2) {
			margin-right: 32px;
			position: relative;
			&::after {
				background-color: ${({ theme }) => theme.colors.white};
				bottom: 0;
				content: '';
				height: 28px;
				position: absolute;
				right: -16px;
				top: 0;
				width: 2px;
			}
		}
	}
`;

const StyledSingleSocialMediaWrapper = styled.a`
	margin-right: 10px;
	position: relative;
	width: 60px;
	height: 60px;
	&:last-of-type {
		margin-right: 0;
	}
	@media ${({ theme }) => theme.device.w_768} {
		margin-left: 15px;
		margin-right: 0;
	}

	&.visible {
		display: block;
	}
	&.hidden {
		display: none;
	}
	svg {
		height: 60px;
		left: 0;
		position: absolute;
		top: 0;
		width: 60px;
	}
	&.hover {
		div {
			background-image: url(${(props) => props.iconHover});
			transform: scale(1.1, 1.1);
		}
		svg {
			.progress__circle {
				stroke-width: 1px;
				stroke: transparent;
				transition: stroke 0.2s ease-in-out;
			}
			.progress__path {
				stroke-dashoffset: 0;
			}
		}
		img {
			transform: scale(1.1);
		}
	}
	.progress__circle {
		fill: none;
		stroke: #fff;
		stroke-width: 1px;
	}
	.progress__path {
		fill: none;
		stroke: #04e99d;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		stroke-width: 1px;
		transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
	}
`;

const StyledSocialMediaIcon = styled.div`
	background-image: url(${(props) => props.icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	height: 60px;
	left: 0;
	top: 0;
	transition: transform 0.2s ease-in-out;
	width: 60px;
	&.visible {
		display: block;
	}
	&.hidden {
		display: none;
	}
`;

const StyledCircleWrapper = styled.div`
	align-items: center;
	bottom: 0;
	display: none;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	@media (min-width: 768px) {
		display: flex;
	}
	&.visible {
		transition-delay: 0.55s;
		opacity: 1;
	}
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
`;

const StyledCircleImg = styled.img`
	position: absolute;
	transform: rotate(${({ circleRotationAngle }) => circleRotationAngle}deg);
	transition: transform 0.6s ease-in-out;

	@media (min-width: 360px) {
		width: 100px;
		height: 100px;
	}
	@media (min-width: 768px) {
		width: 250px;
		height: 250px;
		left: calc(50% - (250px / 2));
		top: calc(50% + (-250px / 2));
	}
	@media (min-width: 1920px) {
		width: 291px;
		height: 291px;
		left: calc(50% - (291px / 2));
		top: calc(50% + (-291px / 2));
	}
`;

const StyledCircleTextWrapper = styled.div`
	color: ${({ theme }) => theme.colors.greyLight};
	display: none;
	font-size: 16px;
	font-weight: 300;
	left: 0;
	top: 50%;
	line-height: 26px;
	margin: 0 auto;
	max-width: 150px;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	height: 250px;
	transform: translate(150%, -50%);

	&.visible {
		opacity: 1;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.2s;
	}

	@media ${({ theme }) => theme.device.w_768} {
		display: flex;
		//justify-content: center;
		align-items: center;
		transform: translate(150%, -50%);
	}

	@media (min-width: 799px) {
		transform: translate(150%, -50%);
	}
	@media (min-width: 960px) {
		@media (orientation: landscape) {
			transform: translate(150%, -50%);
		}
	}
	@media ${({ theme }) => theme.device.w_1024} {
		transform: translate(150%, -50%);
		@media (orientation: landscape) {
			transform: translate(150%, -50%);
		}
	}
	@media ${({ theme }) => theme.device.w_1440} {
		transform: translate(59%, -50%);
		max-width: unset;
		width: auto;
		font-size: 20px;
		line-height: 30px;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		height: 291px;
	}
	@media (min-width: 2300px) {
		transform: translate(57%, -50%);
	}
`;

const StyledCircleText = styled.div`
	text-transform: uppercase;
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 31px;
	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 31px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
	&.visible {
		opacity: 1;
		transition: opacity 0.2s;
		transition-delay: 0.2s;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.2s;
	}
`;

const StyledLogo = styled.img`
	height: auto;
	width: 100px;
	@media (min-width: 960px) {
		@media (orientation: landscape) {
			width: 80px;
		}
	}
	@media ${({ theme }) => theme.device.w_1024} {
		width: 131px;
		@media (orientation: landscape) {
			width: 80px;
		}
	}
	@media (min-width: 1200px) {
		display: none;
	}
`;

const StyledExtentedContentMobileWrapper = styled.div`
	&.visible-children {
		display: block;
		transition: opacity 0.4s;
		transition-delay: 0s;
		@media (min-width: 599px) {
			display: block;
		}
	}
	&.hidden-children {
		display: none;
		transition: opacity 0.4s;
		transition-delay: 0s;
		@media (min-width: 599px) {
			display: block;
		}
	}
`;

const MenuContent = ({
	isMenuVisible,
	mainMenuData,
	submenuData,
	socialMediaData,
	activeSite,
	firstOtherLangSite,
	allPagesOtherLang,
	pageRemoteId,
	menuCircleAnglePosition,
	menuActivePageKey,
	changeMenuVisibility,
	remoteTypeName,
	isChildrenVisible,
}) => {
	const [circleRotationAngle, setCircleRotationAngle] = useState(0);
	const [currentInitialMenuItemKey, setCurrentInitialMenuItemKey] = useState(menuActivePageKey);
	const [shouldMenuAnimate, setMenuAnimation] = useState(false);
	const [rotateCircle, setCircleRotate] = useState(0);

	const dispatch = useDispatch();
	const mainMenuDataLevelOne = mainMenuData.filter((item) => item.level < 2);
	const mainMenuDataLevelTwo = mainMenuData.filter((item) => item.level > 1);

	const socialMediaArray = socialMediaData?.[0]?.socialMedia || [];

	const clickMainMenuItemHandler = () => {
		dispatch(toggleIsChildrenVisible(false));
		changeMenuVisibility();
	};

	const onMouseOutHandler = () => {
		setCircleRotationAngle(menuCircleAnglePosition);
	};

	useEffect(() => {
		const initRotationAngle = 115;
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;
		const angle = Math.atan(screenHeight / screenWidth);
		const pi = Math.PI;
		const angleInDeg = angle * (180 / pi);
		dispatch(changeMenuCircleAnglePosition(-angleInDeg + initRotationAngle));
		setCircleRotationAngle(-angleInDeg + initRotationAngle);
		setCircleRotate(-angleInDeg + initRotationAngle);

		setHeightOnResize();

		// We listen to the resize event
		window.addEventListener('resize', setHeightOnResize);

		return () => window.addEventListener('resize', setHeightOnResize);
	}, []);

	const setHeightOnResize = () => {
		const innerHeight = window.innerHeight;
		dispatch(toggleIsMenuVisible(false));
		setMenuAnimation(false);
		document.documentElement.style.setProperty('--inner-height', `${innerHeight}px`);
	};

	useEffect(() => {
		if (isMenuVisible) {
			setMenuAnimation(true);
		}
	}, [isMenuVisible]);

	const bgWrapperClassList = (() => {
		if (isMenuVisible) {
			return 'visible';
		}
		if (shouldMenuAnimate) {
			return 'hidden';
		}
		return '';
	})();

	return (
		<StyledMenuBgWrapper className={bgWrapperClassList}>
			<StyledModuleWrapper className={isMenuVisible ? 'visible' : 'hidden'}>
				<StyledCircleWrapper className={isMenuVisible && !isChildrenVisible ? 'visible' : 'hidden'}>
					<StyledCircleImg circleRotationAngle={circleRotationAngle} className="mobile" src={WhiteCircleWithDot} />
				</StyledCircleWrapper>

				<StyledLanguageWrapper className="up">
					<StyledLogo src={Logo} />
					<ChangeLanguageCustom
						activeSite={activeSite}
						firstOtherLangSite={firstOtherLangSite}
						allPagesOtherLang={allPagesOtherLang}
						pageRemoteId={pageRemoteId}
						remoteTypeName={remoteTypeName}
						changeMenuVisibility={changeMenuVisibility}
					/>
				</StyledLanguageWrapper>
				<StyledMainContentWrapper className={isMenuVisible ? 'visible' : 'hidden'}>
					{mainMenuDataLevelOne.map((item, key) => {
						const slug = item?.wybierzPodstrone?.[0]?.slug || '';
						const finalSlug = slug === 'strona-glowna' ? '' : slug;
						const title = item?.nazwaPodstrony || '';
						const remoteChildren = item?.remoteChildren || [];
						const description = item?.opisPodstrony || '';

						const numberOfElements = mainMenuDataLevelOne.length;
						const singlePartAngle = 360 / numberOfElements;
						const newArrayKeys = [...mainMenuDataLevelOne.keys()];

						return (
							<>
								{remoteChildren.length === 0 && (
									<MenuItemWrapper key={title} animationDelay={key} className={isMenuVisible ? 'visible' : 'hidden'}>
										<Link
											key={title}
											onMouseEnter={() => {
												setCircleRotationAngle(singlePartAngle * newArrayKeys[key] + rotateCircle);
												dispatch(changeMenuActivePageKey(key));
											}}
											onMouseOut={() => {
												onMouseOutHandler();
												dispatch(changeMenuActivePageKey(currentInitialMenuItemKey));
											}}
											onBlur={() => {
												onMouseOutHandler();
												dispatch(changeMenuActivePageKey(currentInitialMenuItemKey));
											}}
											onClick={
												remoteChildren.length === 0
													? () => {
															clickMainMenuItemHandler();
															dispatch(
																changeMenuCircleAnglePosition(
																	singlePartAngle * newArrayKeys[key] + rotateCircle,
																),
															);
															setCurrentInitialMenuItemKey(key);
													  }
													: () => {
															dispatch(toggleIsChildrenVisible(!isChildrenVisible));
															dispatch(
																changeMenuCircleAnglePosition(
																	singlePartAngle * newArrayKeys[key] + rotateCircle,
																),
															);
															setCurrentInitialMenuItemKey(key);
													  }
											}
											to={`/${getSlug(finalSlug, activeSite)}`}
										>
											<TextHeader
												className={`menu ${isChildrenVisible && key === 1 ? 'mobile-space' : ''} ${
													mainMenuDataLevelOne.length - 1 === key ? 'last-child' : ''
												} `}
											>
												{title}
											</TextHeader>
										</Link>
									</MenuItemWrapper>
								)}

								{remoteChildren.length !== 0 && (
									<MenuItemWrapper animationDelay={key} className={isMenuVisible ? 'visible' : 'hidden'}>
										<MenuItemText
											className={isChildrenVisible ? 'has-submenu' : ''}
											key={title}
											onMouseEnter={() => {
												setCircleRotationAngle(singlePartAngle * newArrayKeys[key] + rotateCircle);
												dispatch(changeMenuActivePageKey(key));
											}}
											onMouseOut={() => {
												onMouseOutHandler();
												dispatch(changeMenuActivePageKey(currentInitialMenuItemKey));
											}}
											onBlur={() => {
												onMouseOutHandler();
												dispatch(changeMenuActivePageKey(currentInitialMenuItemKey));
											}}
											onClick={
												remoteChildren.length === 0
													? () => {
															clickMainMenuItemHandler();
															dispatch(
																changeMenuCircleAnglePosition(
																	singlePartAngle * newArrayKeys[key] + rotateCircle,
																),
															);
															setCurrentInitialMenuItemKey(key);
													  }
													: () => {
															dispatch(toggleIsChildrenVisible(!isChildrenVisible));
															dispatch(
																changeMenuCircleAnglePosition(
																	singlePartAngle * newArrayKeys[key] + rotateCircle,
																),
															);
															setCurrentInitialMenuItemKey(key);
													  }
											}
											to={`/${getSlug(finalSlug, activeSite)}`}
										>
											<TextHeader className={`menu ${isChildrenVisible && key === 1 ? 'mobile-space' : ''} `}>
												{title}
											</TextHeader>
										</MenuItemText>
									</MenuItemWrapper>
								)}
								<StyledCircleTextWrapper className={isMenuVisible ? 'visible' : 'hidden'}>
									<StyledCircleText className={!isChildrenVisible && menuActivePageKey === key ? 'visible' : 'hidden'}>
										{description}
									</StyledCircleText>
								</StyledCircleTextWrapper>
							</>
						);
					})}

					<StyledChildrenWrapper className={isChildrenVisible ? 'visible' : 'hidden'}>
						{mainMenuDataLevelTwo.map((item, key) => {
							const slug = item?.wybierzPodstrone?.[0]?.slug || '';
							const title = item?.nazwaPodstrony || '';
							const uri = item?.wybierzPodstrone?.[0]?.uri || '';
							const finalUri = uri ? uri : slug;
							return (
								<MenuItemWrapper animationDelay={key} className={`child-el ${isChildrenVisible ? 'visible' : 'hidden'}`}>
									<Link key={title} onClick={() => clickMainMenuItemHandler()} to={`/${getSlug(finalUri, activeSite)}`}>
										<TextHeader className="product-categories">{title.toLocaleLowerCase()}</TextHeader>
									</Link>
								</MenuItemWrapper>
							);
						})}
					</StyledChildrenWrapper>
				</StyledMainContentWrapper>
				<StyledExtentedContentMobileWrapper className={!isChildrenVisible ? 'visible-children' : 'hidden-children'}>
					<StyledExtendedContentWrapper className={isMenuVisible ? 'visible' : 'hidden'}>
						<StyledExtendedContentText className="submenu" data-cursor="blend-mode-circle">
							{submenuData.map((item) => {
								const slug = item?.wybierzPodstrone?.[0]?.slug || '';
								const finalSlug = slug === 'strona-glowna' ? '' : slug;
								const title = item?.nazwaPodstrony || '';
								return (
									<Link key={title} onClick={changeMenuVisibility} to={`/${getSlug(finalSlug, activeSite)}`}>
										<TextHeader2 className="light submenu-item ">{title}</TextHeader2>
									</Link>
								);
							})}
						</StyledExtendedContentText>

						<StyledExtendedContentText>
							{socialMediaArray.map((item) => {
								const visibility = item?.widoczneWMenu || '';
								const link = item?.linkUrl || '';
								const logoUrl = item?.logo?.[0]?.url || '';
								const logoGreen = item?.logoZielone?.[0]?.url || '';
								const name = item?.nazwa || '';

								return (
									<StyledSingleSocialMediaWrapper
										className={`footer-social-media custom-cursor-animation
										${visibility ? 'visible' : 'hidden'}
									`}
										key={name}
										href={link}
										target="_blank"
										iconHover={logoGreen}
									>
										<StyledSocialMediaIcon icon={logoUrl} iconHover={logoGreen} />
										<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
											<path
												className="progress__circle"
												d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
											/>
											<path
												className="progress__path"
												d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
												pathLength="1"
											/>
										</svg>
									</StyledSingleSocialMediaWrapper>
								);
							})}
							<StyledLanguageWrapper className="down">
								<ChangeLanguageCustom
									activeSite={activeSite}
									firstOtherLangSite={firstOtherLangSite}
									allPagesOtherLang={allPagesOtherLang}
									pageRemoteId={pageRemoteId}
									remoteTypeName={remoteTypeName}
									changeMenuVisibility={changeMenuVisibility}
								/>
							</StyledLanguageWrapper>
						</StyledExtendedContentText>
					</StyledExtendedContentWrapper>
				</StyledExtentedContentMobileWrapper>
			</StyledModuleWrapper>
		</StyledMenuBgWrapper>
	);
};

export default connect(
	(state) => ({
		isMenuVisible: state.app.isMenuVisible,
		menuCircleAnglePosition: state.app.menuCircleAnglePosition,
		menuActivePageKey: state.app.menuActivePageKey,
		isChildrenVisible: state.app.isChildrenVisible,
	}),
	null,
)(MenuContent);
