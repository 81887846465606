import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	color: var(--text-color);
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.extraLight};
	line-height: 26px;
	position: relative;
	transition: color 0.4s ease-in-out;
	z-index: 3;

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 16px;
		line-height: 30px;
	}
	&.white {
		color: ${({ theme }) => theme.colors.white};
	}
	&.mb-50 {
		margin-bottom: 50px;
	}
`;

const TextDescription2 = ({ className, children, style }) => {
	return <Text style={style} className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextDescription2;
