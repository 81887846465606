import React from 'react';
import styled from 'styled-components';
import LogoAnimation from '../../animations/LogoAnimation';

const StyledLogoWrapper = styled.div`
	content: '';
	height: 120px;
	margin-left: 10px;
	margin-top: 0;
	position: fixed;
	width: 120px;
	z-index: 8;
	@media (min-width: 1024px) {
		height: 200px;
		width: 200px;
		margin-left: 0;
		margin-top: 0;
	}
	@media (min-width: 1920px) {
		margin-left: 30px;
		margin-top: 20px;
	}
`;

const Logo = ({ globalLogoData, activeSite, pageRemoteId, remoteTypeName, remoteParent }) => {
	const globalLogoUrl = globalLogoData?.logoAnimacja?.[0]?.url || '';

	return (
		<StyledLogoWrapper>
			<LogoAnimation
				remoteParent={remoteParent}
				animationJson={globalLogoUrl}
				activeSite={activeSite}
				pageRemoteId={pageRemoteId}
				remoteTypeName={remoteTypeName}
			/>
		</StyledLogoWrapper>
	);
};

export default Logo;
