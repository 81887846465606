import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import TextDescription2 from '../typography/TextDescription2';

const StyledButtonContainer = styled.div`
	background-color: transparent;
	display: block;
	outline: 0;
	overflow: hidden;
`;

const StyledButtonBox = styled.button`
	background: transparent;
	border: none;
	display: inline-block;
	outline: 0;
	overflow-y: hidden;
	position: relative;
	z-index: 1;
	.custom-redactor {
		color: white !important;
	}
	&.black-text {
		div {
			color: black !important;
		}
	}
	&.uppercase {
		div {
			text-transform: uppercase !important;
		}
	}
	&.not-change-color {
		div {
			color: white !important;
			div {
				color: white !important;
			}
		}
		.custom-redactor {
			color: white !important;
		}
	}
	&.hidden {
		display: none;
	}
	&::after {
		background-color: ${({ theme }) => theme.colors.greenAlpha};
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateY(50%);
		transition: all 0.7s cubic-bezier(0.22, 1, 0.36, 1);
		width: 100%;
		z-index: 0;
		// @media (min-width: 768px) {
		// 	transform: translateY(50%);
		// 	background-color: ${({ theme }) => theme.colors.greenAlpha};
		// }
	}
	&.hover {
		z-index: 1;
		&::after {
			background-color: ${({ theme }) => theme.colors.green};
			transform: translateY(0%);
		}
	}
`;

const StyledTextWrapper = styled.div`
	padding: 20px;
`;

const ButtonRectangle = ({ className, href, children, to }) => {
	return (
		<StyledButtonContainer>
			{href && (
				<StyledButtonBox
					className={`rectangle-button custom-cursor-animation ${className}`}
					href={href}
					as="a"
					target="_blank"
					rel="noopener noreferrer"
				>
					<StyledTextWrapper className="hover-class-text-wrapper ">
						{/* <TextDescription2 className="white hover-class-text medium">{children}</TextDescription2> */}
						<TextDescription2 className="hover-class-text medium">{children}</TextDescription2>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!href && to && (
				<StyledButtonBox className={`rectangle-button custom-cursor-animation ${className}`} as={Link} to={to}>
					<StyledTextWrapper className="hover-class-text-wrapper ">
						{/* <TextDescription2 className="white hover-class-text medium">{children}</TextDescription2> */}
						<TextDescription2 className="hover-class-text medium">{children}</TextDescription2>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!to && !href && (
				<StyledButtonBox className={`rectangle-button custom-cursor-animation ${className}`}>
					<StyledTextWrapper className="hover-class-text-wrapper ">
						<TextDescription2 className="white hover-class-text medium">{children}</TextDescription2>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}
		</StyledButtonContainer>
	);
};

export default ButtonRectangle;
