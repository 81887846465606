import { createGlobalStyle } from 'styled-components';
import sairaThin from '../assets/fonts/Saira-Thin.woff2';
import sairaExtraLight from '../assets/fonts/Saira-ExtraLight.woff2';
import sairaLight from '../assets/fonts/Saira-Light.woff2';
import sairaRegular from '../assets/fonts/Saira-Regular.woff2';
import sairaMedium from '../assets/fonts/Saira-Medium.woff2';
import sairaSemiBold from '../assets/fonts/Saira-SemiBold.woff2';
import sairaBold from '../assets/fonts/Saira-Bold.woff2';
import sairaExtraBold from '../assets/fonts/Saira-ExtraBold.woff2';
import sairaBlack from '../assets/fonts/Saira-Black.woff2';

const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 100;
		src: url(${sairaThin}) format('opentype');
	}
	
	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 200;
		src: url(${sairaExtraLight}) format('opentype');
	}

	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 300;
		src: url(${sairaLight}) format('opentype');
	}
	
	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 400;
		src: url(${sairaRegular}) format('opentype');
	}

	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 500;
		src: url(${sairaMedium}) format('opentype');
	}

	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 600;
		src: url(${sairaSemiBold}) format('opentype');
	}

	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 700;
		src: url(${sairaBold}) format('opentype');
	}
	
	@font-face {
		font-family: 'Saira';
		font-display: block;
		font-style: normal;
		font-weight: 800;
		src: url(${sairaExtraBold}) format('opentype');
	}

    @font-face {
      font-family: 'Saira';
      font-display: block;
      font-style: normal;
      font-weight: 900;
      src: url(${sairaBlack}) format('opentype');
    }

	html {
		overflow-x: hidden;
		overflow-y: auto;
		scrollbar-width: none;

	  	&.removeScroll {
          overflow-x: visible !important;
          overflow-y: visible !important;
	    }
	}

	body {
		width: 100%;
		cursor: none;
		padding: 0;
		margin: 0;
	  	position: relative;
		overflow-x: hidden;
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
		background-color: black;
		color:${({ theme }) => theme.colors.white};
		-webkit-tap-highlight-color: transparent;
	  &.removeScroll {
        overflow-x: visible !important;
        overflow-y: hidden;
	  }
	}
	
	button {
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
		cursor: none;
		&:hover{
		cursor: none;
		}
	}
	a {
		cursor: none;
	}
	
	textarea:focus, input:focus{
    outline: none;

	}
	
	.uppercase{
		text-transform: uppercase;
	}
	
	.cursor--canvas {
		width: 100vw;
		height: 100vh;
		z-index: 9999999999999999999999;
	}
	
	a {
	color: unset;
	//cursor: none;
	text-decoration: unset;
		&:focus {
			outline: none;
		}
		&:hover{
			color:${({ theme }) => theme.colors.green};
		}
	}
	
	*, *::before, *::after{
		box-sizing: border-box;
	}
	
	*::selection {
		background-color:${({ theme }) => theme.colors.green};
	}
	*::placeholder {
		font-family: ${({ theme }) => theme.fontFamily.mainFont};
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 0; 
		padding: 0;
	}
	
	p {
		margin: 0;
		padding: 0;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	
	.white {
		color: ${({ theme }) => theme.colors.white};
		
	}
	
	.black {
		color: ${({ theme }) => theme.colors.black};

	}
	
	.green {
		color: ${({ theme }) => theme.colors.green};

	}
	
	.grey-light {
		color: ${({ theme }) => theme.colors.greyLight};

	}
	
	.grey-dark {
		color: ${({ theme }) => theme.colors.greyDark};

	}
	
	.thin {
		font-weight: ${({ theme }) => theme.fontWeight.thin}!important;
	}
	
	.light {
		font-weight: ${({ theme }) => theme.fontWeight.light}!important;
	}
	
	.medium {
		font-weight: ${({ theme }) => theme.fontWeight.medium}!important;
	}
	
	
	#green-line-svg {
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 30px;
    left: 18%;
		@media screen and (min-width: 425px){
			left: 18%
		}
		@media screen and (min-width: 768px){
			left: 11.5%;
		}
		@media screen and (min-width: 1024px){
			left: 11.5%;
		}    
		@media screen and (min-width: 1280px){
			left: 12%;
		}  
		@media screen and (min-width: 1440px){
			left: 8%;
		}
		@media screen and (min-width: 1920px){
			left: 8%;
		}
		@media screen and (min-width: 2500px){
			//transform: translateX(156px);
		}
	}

	::-webkit-scrollbar-track {
		border-radius: 0px;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 0;
		background-color: transparent;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 0px;
		background-color: transparent;
	}

.clsFirst-2,.clsFirst-3,.clsFirst-4,.clsFirst-5,.clsFirst-6,.clsFirst-7,.clsFirst-8,.clsFirst-9,.clsFirst-10,.clsFirst-11,.clsFirst-1{
stroke: var(--logo-color)!important;
}

.clsSecond-2,.clsSecond-3,.clsSecond-4,.clsSecond-5,.clsSecond-6,.clsSecond-7,.clsSecond-8,.clsSecond-9,.clsSecond-10,
.clsSecond-11,.clsSecond-1{
stroke: var(--logo-color)!important;
}

.clsThird-2,.clsThird-3,.clsThird-4,.clsThird-5,.clsThird-6,.clsThird-7,.clsThird-8,.clsThird-9,.clsThird-10,
.clsThird-11,.clsThird-1{
stroke: var(--logo-color)!important;
}

.clsFourth-2,.clsFourth-3,.clsFourth-4,.clsFourth-5,.clsFourth-6,.clsFourth-7,.clsFourth-8,.clsFourth-9,.clsFourth-10,
.clsFourth-11,.clsFourth-1{
stroke: var(--logo-color)!important;
}

.clsFirst-7,.clsSecond-2,.clsThird-1,.clsFourth-5{
stroke: var(--logo-color)!important;
}
.clsThird-1, .clsFourth-5 {
fill: var(--logo-color)!important;
}

.clsFourth-6,.clsThird-8 {
stroke-dasharray: 0, 1000!important;
}

 input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
`;

export default GlobalStyle;
