import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { changeLang } from '../../state/app';
import getSlug from '../../utils/getSlug';
import getNewsOtherLangSlug from '../../utils/getNewsOtherLangSlug';
import setInitialColors from '../../utils/setInitialColors';

const ChangeLanguageWrapper = styled.div`
	font-size: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	height: 60px;
	opacity: 0;
	position: relative;
	text-transform: uppercase;
	width: 60px;

	@media (min-width: 1000px) {
		left: unset;
	}
	svg {
		height: 60px;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 60px;
		.progress__circle {
			fill: none;
			stroke: #fff;
			stroke-width: 1px;
		}

		.progress__path {
			fill: none;
			stroke: #04e99d;
			stroke-dasharray: 1;
			stroke-dashoffset: 1;
			stroke-width: 1px;
			transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
		}
	}

	&.visible {
		opacity: 1;
		pointer-events: all;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}
	&.hidden {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s;
	}
	&.footer {
		left: 0;
		margin-left: auto;
		opacity: 1;
		pointer-events: all;
		right: 0;
		top: 0;
		z-index: 3 !important;
	}
`;

const CurrentLanguageInHiddenList = styled.div`
	align-items: center;
	display: flex;
	height: 60px;
	justify-content: center;
	position: relative;
	width: 60px;

	svg {
		height: 60px;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 60px;
		.progress__circle {
			fill: none;
			stroke: #fff;
			stroke-width: 1px;
		}

		.progress__path {
			fill: none;
			stroke: #04e99d;
			stroke-dasharray: 1;
			stroke-dashoffset: 1;
			stroke-width: 1px;
			transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
		}
	}

	&:hover {
		span {
			color: ${({ theme }) => theme.colors.green};
			transform: scale(1.1, 1.1);
		}

		svg {
			.progress__circle {
				stroke: transparent;
				stroke-width: 1px;
				transition: stroke 0.2s ease-in-out;
			}
			.progress__path {
				stroke-dashoffset: 0;
			}
		}
	}
`;

const CurrentLanguage = styled.div`
	align-items: center;
	display: flex;
	font-size: 18px;
	height: 60px;
	justify-content: center;
	position: relative;
	width: 60px;
	z-index: 2;

	@media (min-width: 1000px) {
		left: unset;
	}

	svg {
		height: 60px;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 60px;
		.progress__circle {
			fill: none;
			stroke: #fff;
			stroke-width: 1px;
		}

		.progress__path {
			fill: none;
			stroke: #04e99d;
			stroke-dasharray: 1;
			stroke-dashoffset: 1;
			stroke-width: 1px;
			transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
		}
	}
	&:hover {
		span {
			color: ${({ theme }) => theme.colors.green};
			transform: scale(1.1, 1.1);
		}

		svg {
			.progress__circle {
				stroke: transparent;
				stroke-width: 1px;
				transition: stroke 0.2s ease-in-out;
			}
			.progress__path {
				stroke-dashoffset: 0;
			}
		}
	}
`;

const OtherLanguagesList = styled.div`
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: transform 0.3s 0.1s ease-in-out, opacity 0.3s 0.1s ease-in-out;
	&.expanded {
		align-items: center;
		display: flex;
		justify-content: center;
		opacity: 1;
		pointer-events: all;
		transform: translateY(-45px);
		z-index: 1;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		&.expanded {
			transform: ${({ footer }) => (footer ? 'translateY(-45px)' : 'translateY(45px)')};
		}
	}
`;

const languageOptions = ['pl', 'en'];

class ChangeLanguageCustom extends React.Component {
	state = {
		isSelectorOpened: false,
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e) => {
		const { isSelectorOpened } = this.state;
		if (isSelectorOpened) {
			if (this.node?.contains(e.target)) {
				return null;
			}
			this.setState({ isSelectorOpened: false });
		}
		return null;
	};

	toggleSelectorState = () => {
		const { isSelectorOpened } = this.state;
		this.setState({ isSelectorOpened: !isSelectorOpened });
	};

	handleChange = (selectedOption, dispatch, pathToNavigate, lang) => {
		const { pageRemoteId, remoteTypeName } = this.props;
		if (selectedOption.value !== lang) {
			window.localStorage.setItem('appUILang', selectedOption.value);
			this.toggleSelectorState();
			dispatch(changeLang(selectedOption.value));
			setInitialColors(pageRemoteId, remoteTypeName);
			navigate(`/${pathToNavigate}`);
		}
	};

	setStateToActiveSite = (dispatch, site) => {
		if (site === 'en') {
			dispatch(changeLang(languageOptions[1]));
		} else {
			dispatch(changeLang(languageOptions[0]));
		}
	};

	changeMenuVisibilityFooter = (isOnFooter) => {
		const { changeMenuVisibility } = this.props;
		if (!isOnFooter) {
			changeMenuVisibility();
		}
	};

	componentDidUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	render() {
		const { activeSite, lang, dispatch, firstOtherLangSite, allPagesOtherLang, pageRemoteId, isOnFooter, isMenuVisible } = this.props;
		const { isSelectorOpened } = this.state;

		const otherLangUrlArray = [];

		allPagesOtherLang.forEach((item) => {
			const { id } = item;
			let slug = '';
			const isSingleNews = item?.isSingleNews || '';
			const isProductsCategoriesPages = item?.isProductsCategoriesPages || '';
			// const isProductPages = item?.isProductPages || '';

			if (isSingleNews) {
				slug = getNewsOtherLangSlug(item.slug, firstOtherLangSite);
			} else if (isProductsCategoriesPages) {
				slug = getSlug(item.uri, firstOtherLangSite);
			} else {
				slug = getSlug(item.slug, firstOtherLangSite);
			}
			const obj = { id, slug };
			otherLangUrlArray.push(obj);
		});
		const otherLangUrlObject = otherLangUrlArray.reduce((prev, curr) => {
			prev[curr.id] = curr.slug;
			return prev;
		}, {});

		const pathToNavigate = otherLangUrlObject[pageRemoteId];
		let isBrowserLangPl;
		let localStorageLang;

		this.setStateToActiveSite(dispatch, activeSite);

		if (typeof window !== 'undefined') {
			localStorageLang = window.localStorage.getItem('appUILang');
			const language = localStorageLang || window.navigator.language;
			isBrowserLangPl = language.includes('pl');
		}

		if (localStorageLang === null && lang !== '') {
			if (isBrowserLangPl && lang !== 'pl') {
				navigate(`/${pathToNavigate}`);
				window.localStorage.setItem('appUILang', 'pl');
			} else if (!isBrowserLangPl && lang === 'pl') {
				navigate(`/${pathToNavigate}`);
				window.localStorage.setItem('appUILang', 'en');
			}
		}

		const classIfFooter = isOnFooter ? 'visible footer' : '';
		const classIfMenu = !isOnFooter && isMenuVisible ? 'visible' : 'hidden';
		const otherLanguages = languageOptions.filter((item) => item !== lang);

		return (
			<>
				<ChangeLanguageWrapper
					/* eslint-disable-next-line no-return-assign */
					ref={(node) => (this.node = node)}
					isOnFooter={isOnFooter}
					className={` ${classIfMenu} ${classIfFooter} change-lang custom-cursor-animation`}
				>
					<CurrentLanguage onClick={this.toggleSelectorState}>
						<span>{lang}</span>
						<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
							<path
								className="progress__circle"
								d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
							/>
							<path
								className="progress__path"
								d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								pathLength="1"
							/>
						</svg>
					</CurrentLanguage>
					<OtherLanguagesList footer={isOnFooter} className={isSelectorOpened ? 'expanded' : ''}>
						<CurrentLanguageInHiddenList
							onClick={() => {
								this.handleChange(otherLanguages, dispatch, pathToNavigate, lang);
								this.changeMenuVisibilityFooter(isOnFooter);
							}}
						>
							<span>{otherLanguages}</span>
							<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
								<path
									className="progress__circle"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
								/>
								<path
									className="progress__path"
									d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									pathLength="1"
								/>
							</svg>
						</CurrentLanguageInHiddenList>
					</OtherLanguagesList>
				</ChangeLanguageWrapper>
			</>
		);
	}
}

export default connect(
	(state) => ({
		isMenuVisible: state.app.isMenuVisible,
		lang: state.app.lang,
	}),
	null,
)(ChangeLanguageCustom);
