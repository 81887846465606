import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { isMobile } from 'react-device-detect';
import themeSettings from '../styles/themeSettings';
import GlobalStyle from '../styles/globalStyles';
import Logo from '../components/common/logo/Logo';
import Menu from '../components/menu/Menu';
import './layout.css';
import CustomCursor from '../components/customCursor/CustomCursor';
import { validateArray } from '../utils/validateArray';
import Footer from '../components/footer/Footer';
import disableBgAnimationOnPageChange from '../utils/disableBgAnimationOnPageChange';
import CookieInfo from '../components/cookieInfo/CookieInfo';

const StyledBackground = styled.div`
	background-color: var(--background-color);
	transition: background-color 0.4s ease-in-out;
	will-change: background-color;
`;
const StyledWrapper = styled.div`
	margin: 0 auto;
	max-width: 100vw;
	padding-right: 0px;
	@media ${({ theme }) => theme.device.w_1024} {
		//padding-right: 80px;
	}
`;

const mergeArrays = (...arrayArguments) => {
	return [].concat(...arrayArguments);
};

const Layout = ({ children, data, pageContext, location }) => {
	const [isMobileDevice, setDevice] = useState(true);
	let pathname = '';
	useEffect(() => {
		setDevice(isMobile);
		pathname = location?.pathname || '';
	}, []);

	if (typeof window !== 'undefined' && location.pathname !== pathname) {
		pathname = location?.pathname || '';
		disableBgAnimationOnPageChange();
	}

	const {
		pojedynczeOtherLang,
		homeOtherLang,
		footer,
		socialMediaModuleData,
		productPagesOtherLang,
		globalLogo,
		singleNewsPagesOtherLang,
		productsCategoriesPagesOtherLang,
		productsCategoriesPages,
		differentPagesOtherLang,
	} = data;
	const remoteTypeName = productsCategoriesPages?.nodes?.[0]?.remoteTypeName || '';
	const remoteParent = productsCategoriesPages?.nodes?.[0]?.remoteParent?.title || '';

	const pojedynczeOtherLangData = pojedynczeOtherLang?.nodes || [];
	const productPagesOtherLangData = productPagesOtherLang?.nodes || [];
	const differentPagesOtherLangData = differentPagesOtherLang?.nodes || [];

	const singleNewsPagesOtherLangData = singleNewsPagesOtherLang?.nodes || [];
	const productsCategoriesPagesOtherLangData = productsCategoriesPagesOtherLang?.nodes || [];
	const singleNewsPagesOtherLangExpanded = [];
	singleNewsPagesOtherLangData.forEach((item) => {
		singleNewsPagesOtherLangExpanded.push({
			id: item?.id || '',
			slug: item?.slug || '',
			isSingleNews: true,
		});
	});
	const productsCategoriesPagesOtherLangDataExpanded = [];
	productsCategoriesPagesOtherLangData.forEach((item) => {
		productsCategoriesPagesOtherLangDataExpanded.push({
			id: item?.id || '',
			slug: item?.slug || '',
			uri: item?.uri || '',
			isProductsCategoriesPages: true,
		});
	});

	const productPagesOtherLangDataExpanded = [];
	productPagesOtherLangData.forEach((item) => {
		productPagesOtherLangDataExpanded.push({
			id: item?.id || '',
			slug: item?.slug || '',
			isProductPages: true,
			categorySlug: item?.wybierzKategorie?.[0]?.slug || '',
		});
	});
	const singlePageData = data?.singlePages?.nodes?.[0] || [];

	const singleNewsPageData = data?.singleNewsPages?.nodes?.[0] || [];
	const productCategoriesPageData = data?.productsCategoriesPages?.nodes?.[0] || [];
	const differentPageData = data?.differentPageData?.nodes?.[0] || [];
	const checkPageName =
		singlePageData?.title || singleNewsPageData.title || productCategoriesPageData.title || differentPageData.title || '';
	const pageName = checkPageName || '';
	const pageRemoteId = pageContext?.remoteId ? pageContext?.remoteId : data?.homepageData?.nodes?.[0]?.id;

	const mainMenuData = pageContext?.menuItems?.data?.modulMenu?.nodes || [];
	const submenuData = pageContext?.menuItems?.data?.modulSubmenu?.nodes || [];
	const socialMediaData = pageContext?.menuItems?.data?.modulSocialMedia?.nodes || [];
	const languageSubpage = pageContext?.site || '';
	const firstOtherLangSiteSubpage = pageContext?.firstOtherLangSite || '';

	const mainMenuDataHome = data?.menuModuleData?.nodes || [];
	const submenuDataHome = data?.submenuModuleData?.nodes || [];
	const socialMediaDataHome = data?.socialMediaModuleData?.nodes || [];
	const languageHome = data?.homepageData?.nodes?.[0]?.language || '';
	const homeOtherLangData = homeOtherLang?.nodes || [];
	const firstOtherLangSiteHome = languageHome === 'pl' ? 'en' : 'pl';
	const finalMenuData = !pageName ? mainMenuDataHome : mainMenuData;
	const finalSubmenuData = !pageName ? submenuDataHome : submenuData;
	const finalSocialMediaData = !pageName ? socialMediaDataHome : socialMediaData;
	const finalLanguage = !pageName ? languageHome : languageSubpage;
	const finalFirstOtherLangSite = !pageName ? firstOtherLangSiteHome : firstOtherLangSiteSubpage;

	const allPagesOtherLang = mergeArrays(
		validateArray(pojedynczeOtherLangData),
		validateArray(productPagesOtherLangData),
		validateArray(singleNewsPagesOtherLangExpanded),
		validateArray(productsCategoriesPagesOtherLangDataExpanded),
		validateArray(productPagesOtherLangDataExpanded),
		validateArray(differentPagesOtherLangData),
	);

	const finalAllPagesOtherLang = allPagesOtherLang.length === 0 ? homeOtherLangData : allPagesOtherLang;
	const globals = pageContext?.globals?.data || [];
	const footerData = globals?.footer?.nodes?.[0]?.stopka || [];
	const footerSocialMediaData = globals?.socialMediaModuleData?.nodes?.[0]?.socialMedia || [];
	const footerDataHome = footer?.nodes?.[0]?.stopka || [];
	const footerSocialMediaHome = socialMediaModuleData?.nodes?.[0]?.socialMedia || [];

	const finalFooterData = !pageName ? footerDataHome : footerData;
	const finalFooterSocialMediaData = !pageName ? footerSocialMediaHome : footerSocialMediaData;

	const globalLogoData = globalLogo?.nodes?.[0] || globals?.globalLogo?.nodes?.[0] || [];

	const cookieInfoData = data?.globalCookies?.nodes?.[0] || [];
	return (
		<ThemeProvider theme={themeSettings}>
			<StyledBackground id="layout-bg">
				<GlobalStyle />
				{!isMobileDevice && <CustomCursor currentLang={finalLanguage} pageRemoteId={pageRemoteId} />}

				<Menu
					mainMenuData={finalMenuData}
					submenuData={finalSubmenuData}
					socialMediaData={finalSocialMediaData}
					activeSite={finalLanguage}
					firstOtherLangSite={finalFirstOtherLangSite}
					allPagesOtherLang={finalAllPagesOtherLang}
					pageRemoteId={pageRemoteId}
					remoteTypeName={remoteTypeName}
				/>
				<Logo
					globalLogoData={globalLogoData}
					activeSite={finalLanguage}
					pageRemoteId={pageRemoteId}
					remoteTypeName={remoteTypeName}
					remoteParent={remoteParent}
				/>
				<StyledWrapper>{children}</StyledWrapper>
				<Footer
					activeSite={finalLanguage}
					data={finalFooterData}
					socialMediaData={finalFooterSocialMediaData}
					firstOtherLangSite={finalFirstOtherLangSite}
					allPagesOtherLang={finalAllPagesOtherLang}
					pageRemoteId={pageRemoteId}
				/>
				<CookieInfo cookieInfoData={cookieInfoData} />
			</StyledBackground>
		</ThemeProvider>
	);
};

export default Layout;
