import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	line-height: 30px;

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 20px;
		line-height: 30px;
	}
	&.mb-50 {
		margin-bottom: 15px;
		@media (min-width: 1024px) {
			margin-bottom: 50px;
		}
	}
	transition: color 0.3s ease-in-out;
	&.green {
		color: ${({ theme }) => theme.colors.green};
	}
	&.white {
		color: ${({ theme }) => theme.colors.white};
	}
`;

const TextHeader2 = ({ className, children, style }) => {
	return <Text style={style} className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextHeader2;
