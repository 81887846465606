import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 40px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 54px;

	&.write-us {
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		text-align: center;
		text-transform: uppercase;
		font-size: 35px;
		line-height: 48px;

		@media ${({ theme }) => theme.device.w_768} {
			font-size: 48px;
			line-height: 70px;
		}

		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 56px;
			line-height: 80px;
		}

		@media ${({ theme }) => theme.device.w_1440} {
			font-size: 60px;
			line-height: 84px;
		}
	}
	&.photoDescription {
		font-size: 36px;
		line-height: 54px;

		@media ${({ theme }) => theme.device.w_768} {
			font-size: 40px;
			line-height: 60px;
		}
		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 56px;
			line-height: 80px;
		}
		@media ${({ theme }) => theme.device.w_1920} {
			font-size: 60px;
			line-height: 90px;
		}
	}
	&.footer-email {
		font-size: 26px;
		line-height: 35px;
		@media (min-width: 1024px) {
			font-size: 54px;
			line-height: 80px;
		}
		@media (min-width: 1440px) {
			font-size: 50px;
		}
		@media (min-width: 1920px) {
			font-size: 58px;
			line-height: 54px;
		}
	}

	@media ${({ theme }) => theme.device.w_1440} {
		font-size: 48px;
		line-height: 70px;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 60px;
		line-height: 84px;
	}
`;

const TextBanner = ({ className, children, textColorState }) => {
	return <Text textColor={textColorState} className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default connect(
	(state) => ({
		textColorState: state.app.textColorState,
	}),
	null,
)(TextBanner);
